<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      color="secondary"
      dark
    >
      <v-card class="text-center" color="grey darken-3 pb-4" flat>
        <v-responsive class="pt-4">
          <v-avatar class="mx-auto" size="70" color="primary">
            <span class="white--text headline">{{ iniziali }}</span>
          </v-avatar>
        </v-responsive>
        <v-card-text class="pb-0">
          <div class="subtitle-1">
            {{ $store.state.nome_user }}
          </div>
          <div class="grey--text">
            {{ $store.state.username_user }}
          </div>
        </v-card-text>
        <!-- <v-card-actions class="text-center">
          <v-btn text color="grey" class="mx-auto" to="/profilo">
            <span>Gestisci Profilo</span>
          </v-btn>
        </v-card-actions> -->
      </v-card>
      <h3 class="white--text">{{  }}</h3>
      <v-list>
        <v-list-item
          v-for="link in $store.state.permessi >= 8 ? links : $store.state.permessi >= 3 ? links_master : links_non_admin"
          :key="link.text"
          router
          :to="link.route"
        >
          <v-list-item-action>
            <v-icon>
              {{ link.icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ link.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer/>

      <v-btn text @click="logout()">
        <span>Log-Out</span>
        <v-icon right>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
  </div>

</template>

<script>
  import router from "../router";

  export default {
    data: () => ({ 
      drawer: null,
      links: [
        { icon: 'mdi-ticket-confirmation', text: 'Le mie prenotazioni', route: '/prenotazioni' },
        { icon: 'mdi-handshake', text: 'Clienti ', route: '/clienti' },
        { icon: 'mdi-truck', text: 'Vettori ', route: '/vettori' },
        { icon: 'mdi-account-multiple', text: 'Utenze', route: '/utenze' },
        { icon: 'mdi-package-down', text: 'Tipi scarico ', route: '/tipi-scarico' },
        { icon: 'mdi-warehouse', text: 'Comparti ', route: '/comparti' },
        { icon: 'mdi-ticket', text: 'Stati prenotazione ', route: '/stati-prenotazione' },
        { icon: 'mdi-cog', text: 'Ambiente', route: '/ambiente' },
        { icon: 'mdi-calendar-remove', text: 'Date chiusura', route: '/date-chiusura' },
      ],
      links_non_admin: [
        { icon: 'mdi-ticket-confirmation', text: 'Le mie prenotazioni', route: '/prenotazioni' },
      ],
      links_master: [
        { icon: 'mdi-ticket-confirmation', text: 'Le mie prenotazioni', route: '/prenotazioni' },
        { icon: 'mdi-account-multiple', text: 'Utenze', route: '/utenze' }
      ]
    }),
    methods: {
      logout() {
        localStorage.setItem("jwt", "")
        router.push("/")
      }
    },
    computed: {
      title() {
        var title = this.links.filter((el) => el.route == this.$route.path)
        if (title.length > 0) {
          return title[0]["text"]
        }
        else {
          return this.$route.name
        }
      },
      iniziali: function () {
        var buf = ''
        var i = 1
        var nome = this.$store.state.nome_user

        if (nome.charAt(0) !== ' ') {
          buf = nome.charAt(0)
        } else {
          i = 0
        }
        for (i; i < nome.length; i++) {
          if (nome.charAt(i) === ' ') {
            buf += nome.charAt(i + 1)
            i = nome.length
          }
        }
        return buf.toUpperCase()
      }
    }
  }
</script>