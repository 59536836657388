<template>
    <v-container class="fill-height pa-0" fluid>
      <v-data-table
        :headers="headers"
        :items="$store.state.stati_prenotazione"
        style="width:100%"
        height="calc(100vh - 200px)"
        :items-per-page="15"
        :search="search"
        no-results-text="Nessun risultato"
        fixed-header
        :footer-props="{
          'items-per-page-options': [15, 25, 50, -1],
          'items-per-page-text': 'Elementi per pagina',
          'items-per-page-all-text': 'Tutti',
        }"
        class="mt-3"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              label="Cerca"
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
            ></v-text-field>
  
            <v-spacer></v-spacer>
  
            <v-btn
              v-blur
              fab
              small
              color="primary"
              @click="openAggiungiStato"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.opaco`]="{ item }">
          <v-icon color="green" v-if="item.opaco"
            >mdi-check-bold</v-icon
          >
          <v-icon color="red" v-else
            >mdi-close-thick</v-icon
          >
        </template>
  
        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="item.stato == 1">
            <v-icon small class="mr-2" @click="openModificaStato(item)" >
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="openModificaStatoStato(item, 2)" v-if="item.id_stato != 1"> 
              mdi-cancel 
            </v-icon>
            <v-icon small @click="openModificaStatoStato(item, 0)" v-if="item.id_stato != 1"> 
              mdi-delete 
            </v-icon>
          </div>
          <div v-else>
            <v-btn
              color="grey lighten-2"
              @click="openModificaStatoStato(item, 1)"
              elevation="0"
            >
              Attiva
            </v-btn>
          </div>
        </template>
      </v-data-table>
  
      <v-dialog v-model="stato_dialog" max-width="800px" persistent>
        <AggiungiModificaStato v-model="stato_edit" ref="stato_dialog" @close-dialog="closeDialog" @update-stati="$store.dispatch('getStatiPrenotazione')"/>
      </v-dialog>
  
      <v-dialog v-model="stato_dialog_delete" max-width="500px" persistent>
        <v-card style="text-align: center;">
          <v-card-title class="text-h5" style="justify-content: center;">Attenzione</v-card-title>
          <v-card-text class="pb-0">
            <span class="black--text text-subtitle-1">
              Sei sicuro di voler {{ stato_delete["stato"] == 2 ? "disabilitare" : stato_delete["stato"] == 1 ? "abilitare" : "cancellare" }} lo stato <b>{{ stato_delete["descrizione"] }}</b>?
            </span>
            <!-- <br>
            <span v-if="stato_delete['stato'] == 0" class="black--text text-subtitle-1">
              Verranno inoltre cancellate le relative prenotazioni.
            </span> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              color="secondary" 
              text 
              @click="closeDialog"
            >
              Annulla
            </v-btn>
            <v-btn 
              color="primary" 
              text 
              @click="modificaStatoStato"
              :loading="stato_dialog_delete_loading"
              :disabled="stato_dialog_delete_loading"
              >
              Conferma
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import AggiungiModificaStato from '../components/AggiungiModificaStato.vue'
  
  export default {
    data: () => ({
      search: "",
      loading: false,
      stato_dialog: false,
      stato_dialog_delete: false,
      stato_dialog_delete_loading: false,
      stato_edit: {},
      stato_delete: {},
      stato_edit_default: {
        id_stato: null,
        descrizione: "",
        opaco: false,
      },
      headers: [
        {
          text: "Descrizione",
          value: "descrizione",
        },
        {
          text: "Opaco",
          value: "opaco",
        },
        { text: "Azioni", value: "actions", sortable: false, align: "center"},
      ],
    }),
    components: {
      AggiungiModificaStato
    },
    mounted() {
      if (!this.$store.state.stati_prenotazione || this.$store.state.stati_prenotazione.length == 0) {
        this.loading = true
        this.$store.dispatch("getStatiPrenotazione").finally(() => {this.loading = false})
      }
    },
    methods: {
      closeDialog() {
        this.stato_dialog = false
        this.stato_dialog_delete = false
        this.stato_edit = Object.assign({}, this.stato_edit_default)
        this.stato_delete = Object.assign({}, this.stato_edit_default)
      },
      openAggiungiStato() {
        this.stato_edit = Object.assign({}, this.stato_edit_default)
        this.stato_edit["nuovo_stato"] = true
        this.stato_dialog = true
      },
      openModificaStato(item) {
        this.stato_edit = Object.assign({}, item)
        this.stato_edit["nuovo_stato"] = false
        this.stato_dialog = true
      },
      openModificaStatoStato(item, stato) {        
        this.stato_delete = Object.assign({}, item)
        this.stato_delete["stato"] = stato
        this.stato_dialog_delete = true
      },
      modificaStatoStato() {
        this.stato_dialog_delete_loading = true
  
        var params = {
          id_stato: this.stato_delete.id_stato,
          stato: this.stato_delete.stato
        }
  
        var dicitura = ""
        switch (params.stato) {
          case 0:
            dicitura = "elimin"
            break;
          case 1:
            dicitura = "abilit"
            break;
          case 2:
            dicitura = "disabilit"
            break;
        
          default:
            dicitura = "elimin"
            break;
        }

        this.axios
          .delete(`${this.$store.state.endpoint}/stati-prenotazione?${new URLSearchParams(params).toString()}`)
          .then(() => {
            this.$store.state.snackbar = true
            this.$store.state.snackbar_text = `Stato ${dicitura}ato con successo!`
            this.$store.state.snackbar_color = "success"
  
            this.$store.dispatch("getStatiPrenotazione")
            this.closeDialog()
          })
          .catch((e) => {
            this.$store.state.snackbar_text = `Non è stato possibile ${dicitura}are lo stato`

            if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
              this.$store.state.snackbar_text += ": " + e.response.data.message
            }
            else {
              this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
            }
            
            this.$store.state.snackbar = true
            this.$store.state.snackbar_color = "error"
            console.log("Errore stato", e)
          })
          .finally(() => {
            this.stato_dialog_delete_loading = false
          });
      }
    }
  }
  </script>
  
  <style>
  
  </style>