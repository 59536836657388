<template>
    <v-container class="fill-height pa-0" fluid>
      <v-data-table
        :headers="headers"
        :items="$store.state.tipi_scarico"
        style="width:100%"
        height="calc(100vh - 200px)"
        :items-per-page="15"
        :search="search"
        no-results-text="Nessun risultato"
        fixed-header
        :footer-props="{
          'items-per-page-options': [15, 25, 50, -1],
          'items-per-page-text': 'Elementi per pagina',
          'items-per-page-all-text': 'Tutti',
        }"
        class="mt-3"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              label="Cerca"
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
            ></v-text-field>
  
            <v-spacer></v-spacer>
  
            <v-btn
              v-blur
              fab
              small
              color="primary"
              @click="openAggiungiTipoScarico"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
  
        <template v-slot:[`item.descrizione`]="{ item }">
          <v-chip :color="item.colore" dark>
            {{ item.descrizione }}
          </v-chip>
        </template>

        <template v-slot:[`item.ora_cutoff`]="{ item }">
          {{ item.ora_cutoff && item.ora_cutoff.split(":").length > 2 ? item.ora_cutoff : item.ora_cutoff }}
        </template>

        <template v-slot:[`item.fascia_1`]="{ item }">
          {{ getFormattedFasciaOraria(item.inizio_fascia_1, item.fine_fascia_1) }}
        </template>

        <template v-slot:[`item.fascia_2`]="{ item }">
          {{ getFormattedFasciaOraria(item.inizio_fascia_2, item.fine_fascia_2) }}
        </template>
  
        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="item.stato == 1">
            <v-icon small class="mr-2" @click="openModificaTipoScarico(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="openModificaStatoTipoScarico(item, 2)"> 
              mdi-cancel 
            </v-icon>
            <v-icon small @click="openModificaStatoTipoScarico(item, 0)"> 
              mdi-delete 
            </v-icon>
          </div>
          <div v-else>
            <v-btn
              color="grey lighten-2"
              @click="openModificaStatoTipoScarico(item, 1)"
              elevation="0"
            >
              Attiva
            </v-btn>
          </div>
        </template>
      </v-data-table>
  
      <v-dialog v-model="tipo_scarico_dialog" max-width="800px" persistent>
        <AggiungiModificaTipoScarico v-model="tipo_scarico_edit" ref="tipo_scarico_dialog" @close-dialog="closeDialog" @update-tipi-scarico="$store.dispatch('getTipiScarico')"/>
      </v-dialog>
  
      <v-dialog v-model="tipo_scarico_dialog_delete" max-width="500px" persistent>
        <v-card style="text-align: center;">
          <v-card-title class="text-h5" style="justify-content: center;">Attenzione</v-card-title>
          <v-card-text class="pb-0">
            <span class="black--text text-subtitle-1">
              Sei sicuro di voler {{ tipo_scarico_delete["stato"] == 2 ? "disabilitare" : tipo_scarico_delete["stato"] == 1 ? "abilitare" : "cancellare" }} il tipo scarico <b>{{ tipo_scarico_delete["descrizione"] }}</b>?
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              color="secondary" 
              text 
              @click="closeDialog"
            >
              Annulla
            </v-btn>
            <v-btn 
              color="primary" 
              text 
              @click="modificaStatoTipoScarico"
              :loading="tipo_scarico_dialog_delete_loading"
              :disabled="tipo_scarico_dialog_delete_loading"
              >
              Conferma
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import AggiungiModificaTipoScarico from '../components/AggiungiModificaTipoScarico.vue'
  
  export default {
    data: () => ({
      search: "",
      loading: false,
      tipo_scarico_dialog: false,
      tipo_scarico_dialog_delete: false,
      tipo_scarico_dialog_delete_loading: false,
      tipo_scarico_edit: {},
      tipo_scarico_delete: {},
      tipo_scarico_edit_default: {
        id_tipo_scarico: null,
        descrizione: "",
        giorni_preavviso: "",
        ora_cutoff: "",
        ora_max_disdetta: "",
        durata_scarico: "",
        inizio_fascia_1: "",
        fine_fascia_1: "",
        inizio_fascia_2: "",
        fine_fascia_2: "",
        max_scarichi_giorno: "",
        max_scarichi_fascia: "",
        colore: "",
      },
      headers: [
        {
          text: "Descrizione",
          value: "descrizione",
        },
        {
          text: "Preavviso (gg.)",
          value: "giorni_preavviso",
        },
        {
          text: "Ora cutoff",
          value: "ora_cutoff",
        },
        {
          text: "Preavviso disdetta (h)",
          value: "ora_max_disdetta",
        },
        {
          text: "Durata scarico (h)",
          value: "durata_scarico",
        },
        {
          text: "Fascia 1",
          value: "fascia_1",
        },
        {
          text: "Fascia 2",
          value: "fascia_2",
        },
        {
          text: "Max scarichi giorno",
          value: "max_scarichi_giorno",
        },
        {
          text: "Contemporaneità",
          value: "max_scarichi_fascia",
        },
        { text: "Azioni", value: "actions", sortable: false, align: "center"},
      ],
    }),
    components: {
      AggiungiModificaTipoScarico
    },
    mounted() {
      if (!this.$store.state.tipi_scarico || this.$store.state.tipi_scarico.length == 0) {
        this.loading = true
        this.$store.dispatch("getTipiScarico").finally(() => {this.loading = false})
      }
    },
    methods: {
      closeDialog() {
        this.tipo_scarico_dialog = false
        this.tipo_scarico_dialog_delete = false
        this.tipo_scarico_edit = Object.assign({}, this.tipo_scarico_edit_default)
        this.tipo_scarico_delete = Object.assign({}, this.tipo_scarico_edit_default)
      },
      openAggiungiTipoScarico() {
        this.tipo_scarico_edit = Object.assign({}, this.tipo_scarico_edit_default)
        this.tipo_scarico_edit["nuovo_tipo_scarico"] = true
        this.tipo_scarico_dialog = true
      },
      openModificaTipoScarico(item) {
        for (var prop in item) {
          if (Object.prototype.hasOwnProperty.call(item, prop) && item[prop]) {
            item[prop] = `${item[prop]}`
          }
        }

        this.tipo_scarico_edit = Object.assign({}, item)
        this.tipo_scarico_edit["nuovo_tipo_scarico"] = false
        this.tipo_scarico_dialog = true
      },
      openModificaStatoTipoScarico(item, stato) {
        // for (var prop in item) {
        //   if (Object.prototype.hasOwnProperty.call(item, prop) && item[prop]) {
        //     item[prop] = `${item[prop]}`
        //   }
        // }
        
        this.tipo_scarico_delete = Object.assign({}, item)
        this.tipo_scarico_delete["stato"] = stato
        this.tipo_scarico_dialog_delete = true
      },
      modificaStatoTipoScarico() {
        this.tipo_scarico_dialog_delete_loading = true
  
        var params = {
          id_tipo_scarico: this.tipo_scarico_delete.id_tipo_scarico,
          stato: this.tipo_scarico_delete.stato
        }
  
        var dicitura = ""
        switch (params.stato) {
          case 0:
            dicitura = "elimin"
            break;
          case 1:
            dicitura = "abilit"
            break;
          case 2:
            dicitura = "disabilit"
            break;
        
          default:
            dicitura = "elimin"
            break;
        }

        this.axios
          .delete(`${this.$store.state.endpoint}/tipi-scarico?${new URLSearchParams(params).toString()}`)
          .then(() => {
            this.$store.state.snackbar = true
            this.$store.state.snackbar_text = `Tipo scarico ${dicitura}ato con successo!`
            this.$store.state.snackbar_color = "success"
  
            this.$store.dispatch("getTipiScarico")
            this.closeDialog()
          })
          .catch((e) => {
            this.$store.state.snackbar_text = `Non è stato possibile ${dicitura}are il tipo scarico`

            if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
              this.$store.state.snackbar_text += ": " + e.response.data.message
            }
            else {
              this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
            }
            
            this.$store.state.snackbar = true
            this.$store.state.snackbar_color = "error"
            console.log("Errore tipo scarico", e)
          })
          .finally(() => {
            this.tipo_scarico_dialog_delete_loading = false
          });
      },
      getFormattedFasciaOraria(inizio, fine) {
        if (inizio && fine) {
          return `${inizio} - ${fine}`
        }
      }
    }
  }
  </script>
  
  <style>
  
  </style>