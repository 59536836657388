<template>
    <v-container class="fill-height pa-0" fluid>
      <v-data-table
        :headers="headers"
        :items="$store.state.comparti"
        style="width:100%"
        height="calc(100vh - 200px)"
        :items-per-page="15"
        :search="search"
        no-results-text="Nessun risultato"
        fixed-header
        :footer-props="{
          'items-per-page-options': [15, 25, 50, -1],
          'items-per-page-text': 'Elementi per pagina',
          'items-per-page-all-text': 'Tutti',
        }"
        class="mt-3"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              label="Cerca"
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
            ></v-text-field>
  
            <v-spacer></v-spacer>
  
            <v-btn
              v-blur
              fab
              small
              color="primary"
              @click="openAggiungiComparto"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.fascia_1`]="{ item }">
          {{ getFormattedFasciaOraria(item.inizio_fascia_1, item.fine_fascia_1) }}
        </template>

        <template v-slot:[`item.fascia_2`]="{ item }">
          {{ getFormattedFasciaOraria(item.inizio_fascia_2, item.fine_fascia_2) }}
        </template>
  
        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="item.stato == 1">
            <v-icon small class="mr-2" @click="openModificaComparto(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="openModificaStatoComparto(item, 2)"> 
              mdi-cancel 
            </v-icon>
            <v-icon small @click="openModificaStatoComparto(item, 0)"> 
              mdi-delete 
            </v-icon>
          </div>
          <div v-else>
            <v-btn
              color="grey lighten-2"
              @click="openModificaStatoComparto(item, 1)"
              elevation="0"
            >
              Attiva
            </v-btn>
          </div>
        </template>
      </v-data-table>
  
      <v-dialog v-model="comparto_dialog" max-width="800px" persistent>
        <AggiungiModificaComparto v-model="comparto_edit" ref="comparto_dialog" @close-dialog="closeDialog" @update-comparti="$store.dispatch('getComparti')"/>
      </v-dialog>
  
      <v-dialog v-model="comparto_dialog_delete" max-width="500px" persistent>
        <v-card style="text-align: center;">
          <v-card-title class="text-h5" style="justify-content: center;">Attenzione</v-card-title>
          <v-card-text class="pb-0">
            <span class="black--text text-subtitle-1">
              Sei sicuro di voler {{ comparto_delete["stato"] == 2 ? "disabilitare" : comparto_delete["stato"] == 1 ? "abilitare" : "cancellare" }} il comparto <b>{{ comparto_delete["codice"] }}</b>?
            </span>
            <br>
            <span v-if="comparto_delete['stato'] == 0" class="black--text text-subtitle-1">
              Verranno inoltre cancellate le relative prenotazioni.
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              color="secondary" 
              text 
              @click="closeDialog"
            >
              Annulla
            </v-btn>
            <v-btn 
              color="primary" 
              text 
              @click="cancellaComparto"
              :loading="comparto_dialog_delete_loading"
              :disabled="comparto_dialog_delete_loading"
              >
              Conferma
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import AggiungiModificaComparto from '../components/AggiungiModificaComparto.vue'
  
  export default {
    data: () => ({
      search: "",
      loading: false,
      comparto_dialog: false,
      comparto_dialog_delete: false,
      comparto_dialog_delete_loading: false,
      comparto_edit: {},
      comparto_delete: {},
      comparto_edit_default: {
        id_comparto: null,
        codice: "",
        slot: "",
        inizio_fascia_1: "",
        fine_fascia_1: "",
        inizio_fascia_2: "",
        fine_fascia_2: "",
        max_bancali: "",
        data_ultima_modifica: "",
        user_ultima_modifica: "",
        stato: ""
      },
      headers: [
        {
          text: "Codice",
          value: "codice",
        },
        {
          text: "Scarichi cont.",
          value: "slot",
        },
        {
          text: "Fascia 1",
          value: "fascia_1",
        },
        {
          text: "Fascia 2",
          value: "fascia_2",
        },
        {
          text: "Max bancali giorno",
          value: "max_bancali",
        },
        { text: "Azioni", value: "actions", sortable: false, align: "center"},
      ],
    }),
    components: {
      AggiungiModificaComparto
    },
    mounted() {
      if (!this.$store.state.comparti || this.$store.state.comparti.length == 0) {
        this.loading = true
        this.$store.dispatch("getComparti").finally(() => {this.loading = false})
      }
    },
    methods: {
      closeDialog() {
        this.comparto_dialog = false
        this.comparto_dialog_delete = false
        this.comparto_edit = Object.assign({}, this.comparto_edit_default)
        this.comparto_delete = Object.assign({}, this.comparto_edit_default)
      },
      openAggiungiComparto() {
        this.comparto_edit = Object.assign({}, this.comparto_edit_default)
        this.comparto_edit["nuovo_comparto"] = true
        this.comparto_dialog = true
      },
      openModificaComparto(item) {
        for (var prop in item) {
          if (Object.prototype.hasOwnProperty.call(item, prop) && item[prop]) {
            item[prop] = `${item[prop]}`
          }
        }

        this.comparto_edit = Object.assign({}, item)
        this.comparto_edit["nuovo_comparto"] = false
        this.comparto_dialog = true
      },
      openModificaStatoComparto(item, stato) {
        // for (var prop in item) {
        //   if (Object.prototype.hasOwnProperty.call(item, prop) && item[prop]) {
        //     item[prop] = `${item[prop]}`
        //   }
        // }
        
        this.comparto_delete = Object.assign({}, item)
        this.comparto_delete.stato = stato
        this.comparto_dialog_delete = true
      },
      cancellaComparto() {
        this.comparto_dialog_delete_loading = true
  
        var params = {
          id_comparto: this.comparto_delete.id_comparto,
          stato: this.comparto_delete.stato
        }

        var dicitura = ""
        switch (params.stato) {
          case 0:
            dicitura = "elimin"
            break;
          case 1:
            dicitura = "abilit"
            break;
          case 2:
            dicitura = "disabilit"
            break;
        
          default:
            dicitura = "elimin"
            break;
        }
  
        this.axios
          .delete(`${this.$store.state.endpoint}/comparti?${new URLSearchParams(params).toString()}`)
          .then(() => {
            this.$store.state.snackbar = true
            this.$store.state.snackbar_text = `Comparto ${dicitura}ato con successo!`
            this.$store.state.snackbar_color = "success"
  
            this.$store.dispatch("getComparti")
            this.closeDialog()
          })
          .catch((e) => {
            this.$store.state.snackbar_text = `Non è stato possibile ${dicitura}are il comparto`

            if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
              this.$store.state.snackbar_text += ": " + e.response.data.message
            }
            else {
              this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
            }

            this.$store.state.snackbar = true
            this.$store.state.snackbar_color = "error"
            console.log("Errore comparto", e)
          })
          .finally(() => {
            this.comparto_dialog_delete_loading = false
          });
      },
      getFormattedFasciaOraria(inizio, fine) {
        if (inizio && fine) {
          return `${inizio} - ${fine}`
        }
      }
    }
  }
  </script>
  
  <style>
  
  </style>