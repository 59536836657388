import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import ImpostaPassword from '../views/ImpostaPsw.vue'
import Prenotazioni from '../views/Prenotazioni.vue'
import Clienti from '../views/Clienti.vue'
import Vettori from '../views/Vettori.vue'
import Utenze from '../views/Utenze.vue'
import TipiScarico from '../views/TipiScarico.vue'
import Comparti from '../views/Comparti.vue'
import StatiPrenotazione from '../views/StatiPrenotazione.vue' 
import DateChiusura from '../views/DateChiusura.vue'
import Ambiente from '../views/Ambiente.vue'
import Test from '../views/Test.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/imposta-psw',
    name: 'Imposta Password',
    component: ImpostaPassword
  },
  {
    path: '/prenotazioni',
    name: 'Prenotazioni',
    component: Prenotazioni
  },
  {
    path: '/clienti',
    name: 'Clienti',
    component: Clienti
  },
  {
    path: '/vettori',
    name: 'Vettori',
    component: Vettori
  },
  {
    path: '/utenze',
    name: 'Utenze',
    component: Utenze
  },
  {
    path: '/tipi-scarico',
    name: 'Tipi Scarico',
    component: TipiScarico
  },
  {
    path: '/comparti',
    name: 'Comparti',
    component: Comparti
  },
  {
    path: '/stati-prenotazione',
    name: 'Stati Prenotazione',
    component: StatiPrenotazione
  },
  {
    path: '/date-chiusura',
    name: 'Date chiusura',
    component: DateChiusura
  },
  {
    path: '/ambiente',
    name: 'Ambiente',
    component: Ambiente
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  }
]

const router = new VueRouter({
  routes
})

//  Cambio dinamicamente il titolo in base alla pagina che sto visualizzando
router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router
