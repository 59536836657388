<template>
  <v-app id="inspire">
    <NavBar v-if="!no_navbar_path.includes($route.name)"/>
    <v-main>
      <router-view v-if="loaded && loaded_ambiente"/>
    </v-main>

    <v-overlay
      :absolute="true"
      :value="!loaded || !$store.state.loaded"
      color="rgba(255, 255, 255, 1)"
      opacity="1"
      style="height: 100vh; z-index: 999"
    >
      <v-progress-circular
        indeterminate
        size="64"
        color="secondary"
      ></v-progress-circular>
    </v-overlay>

    <v-snackbar
      v-model="$store.state.snackbar"
      :timeout="5000"
      :color="$store.state.snackbar_color"
    >
      {{ $store.state.snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="$store.state.snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
  import NavBar from './components/NavBar.vue'
  import router from "./router";

  export default {
    data: () => ({
      loaded: false,
      loaded_ambiente: false,
      no_navbar_path: ["Login", "Imposta Password"]
    }),
    components: {
      NavBar,
    },
    created() {
      //  Chiamata per scaricare i colori del portale ed il logo
      this.$store.dispatch("getAmbiente").then(() => {
        this.loaded_ambiente = true
      })

      if (localStorage.getItem("jwt")) {
        this.axios.defaults.headers.common["X-Auth-Token"] = localStorage.getItem("jwt");
        this.axios
          .get(`${this.$store.state.endpoint}/login`)
          .then(async (response) => {
            localStorage.setItem("jwt", response.data.jwt);
            this.axios.defaults.headers.common["X-Auth-Token"] = localStorage.getItem("jwt");

            this.$store.state.nome_user = response.data.nome
            this.$store.state.username_user = response.data.username
            this.$store.state.telefono_user = response.data.telefono
            this.$store.state.jwt = response.data.jwt
            this.$store.state.permessi = response.data.tipo

            // Rifaccio la chiamata all'ambiente con il JWT per scaricare tutti i dati
            await this.$store.dispatch("getAmbiente")

            this.loaded = true

            //  Login Automatico
            if (this.$route.name == "Login") {
              router.push("/prenotazioni")
            }
          })
          .catch(() => {
            localStorage.setItem("jwt", "")
            this.loaded = true
            if (this.$route.name != "Login") {
              router.push("/")
            }
          });
      }
      else {
        this.loaded = true
        if (this.$route.name != "Login") {
          router.push("/")
        }
      }
    },
    methods: {
      checkJWT() {
        return new Promise((resolve, reject) => {
          this.axios
            .post(`${this.$store.state.endpoint}/check-jwt`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    watch: {
      $route() {
        if (this.$route.name != "Login") {
          this.checkJWT().catch((e) => {
            if (e.response && [401, 400].includes(e.response.status)) {
              this.$store.state.snackbar = true
              this.$store.state.snackbar_text = "Sessione scaduta. Effettuare nuovamente il log-in"
              this.$store.state.snackbar_color = "error"

              router.push("/")
            }
          })
        }        
      },
    },
  }
</script>

<style>
input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
}
</style>
