<template>
  <v-card>
    <v-card-title>Nuova Prenotazione</v-card-title>
    <v-card-text class="px-0">
      <v-stepper v-model="stepper" flat :non-linear="!loading">
        <v-stepper-header>
          <v-stepper-step
            :complete="stepper > 1"
            :editable="stepper > 1 && !loading"
            step="1"
          >
            Dati prenotazione
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="stepper > 2"
            :editable="stepper > 2 && !loading"
            step="2"
          >
            Giorno e Orario
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="stepper > 3"
            :editable="stepper > 3 && !loading"
            step="3"
          >
            Riepilogo
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items style="max-height: calc(90vh - 202px); overflow-y: auto;">
          <v-stepper-content step="1" class="pb-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" class="py-0">
                  <p class="pt-4 mb-2 fake-card-title" style="">Dati prenotazione</p>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete 
                    label="Cliente"
                    v-model="prenotazione.id_cliente"
                    :items="$store.getters.getClientiAttivi"
                    item-value="id_cliente"
                    item-text="ragione_sociale"
                    :rules="zero_obbligatorio_roule"
                    :readonly="[2,4].includes($store.state.permessi)"
                    :append-icon="[2,4].includes($store.state.permessi) ? '' : 'mdi-menu-down'"
                    @change="setComparto"
                  />
                  <v-text-field 
                    label="Numero bancali"
                    v-model="prenotazione.bancali"
                    :rules="[...bancali_obbligatori_roule, ...solo_numeri_roule]"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    label="Tipo scarico"
                    v-model="prenotazione.id_tipo_scarico"
                    :items="$store.getters.getTipiScaricoAttivi"
                    item-value="id_tipo_scarico"
                    item-text="descrizione"
                    :rules="zero_obbligatorio_roule"
                  />
                  <v-text-field 
                    label="Documento di riferimetno"
                    v-model="prenotazione.riferimento_documento"
                    :rules="obbligatorio_roule"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="py-0" v-if="$store.state.permessi >= 8">
                  <v-select
                    label="Comparto"
                    v-model="prenotazione.id_comparto"
                    :items="$store.getters.getCompartiAttivi"
                    item-value="id_comparto"
                    item-text="codice"
                    :rules="zero_obbligatorio_roule"
                  />
                </v-col>
                <v-col class="py-0" cols="12" :sm="$store.state.permessi >= 8 ? 6 : 12">
                  <v-text-field 
                    label="Mittente"
                    v-model="prenotazione.mittente"
                    :maxlength="60"
                    counter="60"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <p class="pt-4 mb-2 fake-card-title" style="">Informazioni sul vettore</p>
                  <!-- Con combobox il valore viene aggiornato solo al blur o alla scelta del elemento. @input.native serve per ovviare a questo problema e abilitare i campi senza aspettare quegli eventi -->
                  <v-combobox 
                    label="Ragione sociale"
                    v-model="prenotazione.rag_soc_vettore"
                    :items="$store.getters.getVettoriAttivi"
                    item-value="id_cliente"
                    item-text="ragione_sociale"
                    :maxlength="60"
                    counter="60"
                    :rules="obbligatorio_roule"
                    :readonly="[1,3].includes($store.state.permessi)"
                    :append-icon="[1,3].includes($store.state.permessi) ? '' : 'mdi-menu-down'"
                    @change="resetVettore()"
                    @input.native="rag_soc_vettore_native=$event.srcElement.value"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-combobox 
                    label="Persona di riferimento"
                    v-model="prenotazione.nome_riferimento_vettore"
                    :disabled="!rag_soc_vettore_native && !prenotazione.rag_soc_vettore"
                    :items="filteredUsers"
                    item-text="nome"
                    @change="completaCampi()"
                    :maxlength="60"
                    counter="60"
                    :rules="obbligatorio_roule"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field 
                    label="Telefono"
                    v-model="prenotazione.telefono_riferimento_vettore"
                    :disabled="!rag_soc_vettore_native && !prenotazione.rag_soc_vettore"
                    :maxlength="15"
                    counter="15"
                    :rules="[...obbligatorio_roule, ...solo_numeri_roule]"
                  />
                </v-col>
                <v-col class="py-0" cols="12">
                  <v-text-field 
                    label="Email"
                    v-model="prenotazione.email_riferimento_vettore"
                    :disabled="!rag_soc_vettore_native && !prenotazione.rag_soc_vettore"
                    :maxlength="60"
                    counter="60"
                    :rules="[...edit_rules_email,...obbligatorio_roule]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea 
                    label="Note generali"
                    v-model="prenotazione.note"
                    rows="3"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2" style="overflow: auto;">
            <v-form ref="form2" v-model="valid2" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <p class="pt-4 fake-card-title">Giorno e Orario</p>
                </v-col>
              </v-row>
              <v-row v-if="stepper == 2">
                <v-col>
                  <v-date-picker
                    v-model="prenotazione.date_picker"
                    locale="it-IT"
                    color="secondary"
                    header-color="primary"
                    :allowed-dates="dateValide"
                    :picker-date.sync="date_watching_prop"
                    first-day-of-week="1"
                    @change="focusSelect()"
                  />
                  
                </v-col>
                <v-col>
                  <v-select
                    v-model="prenotazione.fascia"
                    ref="select"
                    label="Ora di arrivo"
                    :items="formattedFasciaOraria"
                    :disabled="!prenotazione.date_picker"
                    :rules="obbligatorio_roule"
                    no-data-text="Nessuna fascia oraria disponibile"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="3" class="pb-0">
            <v-row>
              <v-col cols="12">
                <p class="pt-4 fake-card-title">Riepilogo</p>
              </v-col>
            </v-row>
            <RiepilogoPrenotazione v-if="stepper == 3" :prenotazione="prenotazione"/>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions class="fake-elevation">
      <v-spacer/>
      <v-btn
        color="secondary"
        text
        @click="closeDialog"
        :disabled="loading"
      >
        Annulla
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="checkPrenotazione"
        :disabled="loading || (stepper == 1 ? !valid : !valid2)"
        :loading="loading"
      >
        {{ stepper <= 2 ? "Prosegui" : "Conferma" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
  
<script>
import RiepilogoPrenotazione from '../components/RiepilogoPrenotazione.vue'

export default {
  data: () => ({
    loading: false,
    stepper: 1,
    valid: false,
    valid2: false,
    prenotazione: {
      id_cliente: null,
      tipo_scarico: "",
      bancali: "",
      riferimento_documento: "",
      mittente: "",
      id_comparto: null,
      rag_soc_vettore: "",
      nome_riferimento_vettore: "",
      telefono_riferimento_vettore: "",
      email_riferimento_vettore: "",
      note: "",
      date_picker: "",
      fascia: null
    },
    date_valide: [],
    date_valide_keys: [],
    date_watching_prop: "",
    rag_soc_vettore_native: "",
    obbligatorio_roule: [v => !!v || 'Campo obbligatorio!'],
    zero_obbligatorio_roule: [v => !!v || v == 0 || 'Campo obbligatorio'],
    bancali_obbligatori_roule: [v => !!v || 'Campo obbligatorio!', v => v > 0 || 'Numero non valido', v => v % 1 == 0 || 'Numero non valido' ],
    solo_numeri_roule: [
      v => !v || !isNaN(v) || 'Inserire un numero valido!',
      v => !v || !v.includes('.') || 'Il campo consente solo numeri!',
      v => !v || !v.includes(',') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('-') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('+') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('e') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('E') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('/') || 'Il campo consente solo numeri!'
    ],
    edit_rules_email: [v => !v || /.+@.+\..+/.test(v) || 'E-mail non valida!'],
  }),
  components: {
    RiepilogoPrenotazione
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    //  Pultisco i form e setto il primo step
    resetForm() {
      this.stepper = 1
      this.$refs.form.reset()
      this.$refs.form2.reset()

      this.$nextTick(() => {
        if (this.$store.getters.getClientiAttivi.length == 1) {
          this.prenotazione.id_cliente = 0;
          this.setComparto()
        }

        if (this.$store.getters.getVettoriAttivi.length == 1) {
          this.prenotazione.rag_soc_vettore = this.$store.state.vettori[0];
          this.$nextTick(() => {
            if (this.filteredUsers.length == 1) {
              this.prenotazione.nome_riferimento_vettore = this.filteredUsers[0]
              this.completaCampi()
            }
          })
        }
      })
    },
    //  Chiudo Dialog
    closeDialog() {
      this.$emit('close-dialog')
      setTimeout(() => {
        this.resetForm();
      },200)
    },
    //  Funzione richiamata dal tasto "Prosegui" per cambiare step e confermare la prenotazione
    checkPrenotazione() {
      if (this.stepper == 1) {
        this.$refs.form.validate()

        this.$nextTick(() => {
          if (!this.valid) {
            return
          }

          this.stepper++

          this.$nextTick(() => {
            this.getDatePrenotazione().then(() => {
              //  Imposto la prima data
              this.prenotazione.date_picker = this.date_valide_keys[0]
              this.focusSelect();
            });
          })
        })
      }
      else if (this.stepper == 2) {
        this.$refs.form2.validate()

        this.$nextTick(() => {
          if (!this.valid) {
            return
          }

          this.stepper++
        })
      }
      else if (this.stepper == 3) {
        this.loading = true

        //  Conferma prenotazione
        this.insertPrenotazione()
        .then((res) => {
          //  Apro documento pdf
          this.$store.dispatch("openFilePrenotazione", {id_prenotazione: res.data.id_prenotazione})
          .then(() => {  
            this.$store.state.snackbar = true
            this.$store.state.snackbar_text = "Scarico prenotato con successo!"
            this.$store.state.snackbar_color = "success"
          })
          .catch((e) => {
            console.log(e)
            this.$store.state.snackbar = true
            this.$store.state.snackbar_text = "Errore nella visualizzazione del documento. Riprova più tardi. La tua prenotazione è stata comunque salvata con successo"
            this.$store.state.snackbar_color = "error"
          })
          .finally(() => {
            this.loading = false
            this.$emit('update-prenotazioni')
            this.closeDialog()
          })
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile caricare la prenotazione"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }
          
          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          this.loading = false
          console.log(e)
        })
      }
    },
    //  Compilo automaticamente i campi del vettore se scelto dal menu a tendina
    completaCampi() {
      if (typeof this.prenotazione.nome_riferimento_vettore == 'object' && this.prenotazione.nome_riferimento_vettore != null) {
        this.prenotazione.telefono_riferimento_vettore = this.prenotazione.nome_riferimento_vettore.telefono
        this.prenotazione.email_riferimento_vettore = this.prenotazione.nome_riferimento_vettore.email
      }
    },
    //  Funzione che scarica le date e le fasce in cui la prenotazione è possibile
    getDatePrenotazione() {
      return new Promise((resolve, reject) => {
        this.loading = true
        var params = {
          mese: this.date_watching_prop.split("-")[1],
          anno: this.date_watching_prop.split("-")[0],
          id_tipo_scarico: this.prenotazione.id_tipo_scarico,
          id_cliente: this.prenotazione.id_cliente,
          id_comparto: this.$store.state.permessi >= 8 ? this.prenotazione.id_comparto : null,
          bancali: this.prenotazione.bancali
        }
  
        this.axios
          .get(`${this.$store.state.endpoint}/date-prenotazione?${new URLSearchParams(params).toString()}`)
          .then((response) => {
            this.date_valide = response.data
            this.date_valide_keys = Object.keys(this.date_valide)
            this.date_valide_keys = this.date_valide_keys.filter(el => this.date_valide[el].length > 0)

            this.loading = false
            resolve()
          })
          .catch((e) => {
            console.log(e)

            this.loading = false
            reject()
          });

      })
    },
    //  Funzione che disabilita le date del calendario non valide
    dateValide (val) {
      return this.date_valide_keys.includes(val)
    },
    //  Funzione che apre il menu a tendina della select nel secondo step
    focusSelect() {
      this.$nextTick(() => {
        if (this.prenotazione.fascia && this.formattedFasciaOraria.filter((el) => el.value == this.prenotazione.fascia).length <= 0) {
          this.prenotazione.fascia = null
        }
      })

      setTimeout(() => {
        this.$refs.select.focus()
        this.$refs.select.isMenuActive = true
      }, 100) 
    },
    insertPrenotazione() {
      return new Promise((resolve, reject) => {
        var id_vettore = null
        var rag_soc_vettore = this.prenotazione.rag_soc_vettore
        var nome_riferimento_vettore = this.prenotazione.nome_riferimento_vettore

        if (typeof this.prenotazione.rag_soc_vettore == 'object' && this.prenotazione.rag_soc_vettore != null) {
          id_vettore = this.prenotazione.rag_soc_vettore.id_cliente
          rag_soc_vettore = this.prenotazione.rag_soc_vettore.ragione_sociale
        }

        if (typeof this.prenotazione.nome_riferimento_vettore == 'object' && this.prenotazione.nome_riferimento_vettore != null) {
          nome_riferimento_vettore = this.prenotazione.nome_riferimento_vettore.nome
        }

        this.axios
          .post(`${this.$store.state.endpoint}/prenotazioni`, {
            id_cliente: this.prenotazione.id_cliente,
            id_tipo_scarico: this.prenotazione.id_tipo_scarico,
            bancali: this.prenotazione.bancali,
            riferimento_documento: this.prenotazione.riferimento_documento,
            id_comparto: this.$store.state.permessi >= 8 ? this.prenotazione.id_comparto : null,
            id_vettore: id_vettore,
            rag_soc_vettore: rag_soc_vettore,
            mittente: this.prenotazione.mittente,
            nome_riferimento_vettore: nome_riferimento_vettore,
            telefono_riferimento_vettore: this.prenotazione.telefono_riferimento_vettore,
            email_riferimento_vettore: this.prenotazione.email_riferimento_vettore,
            note: this.prenotazione.note,
            data: this.prenotazione.date_picker,
            fascia: this.prenotazione.fascia
          })
          .then((res) => {
            resolve(res)
          })
          .catch((e) => {
            console.log("Errore prenotazioni", e)
            reject()
          });
      })
    },
    //  Propongo il comparto di default del cliente selezionato (per admin)
    setComparto() {
      if (this.$store.getters.getClientiAttivi[this.prenotazione.id_cliente]) {
        this.prenotazione.id_comparto = this.$store.getters.getClientiAttivi[this.prenotazione.id_cliente]["id_comparto"]
      }
    },
    //  Richiesta di Mattia di resettare i campi del vettore quando viene cambiata la ragione sociale
    resetVettore() {
      if(this.prenotazione.nome_riferimento_vettore) {
        this.prenotazione.nome_riferimento_vettore = ""
      }

      if(this.prenotazione.telefono_riferimento_vettore) {
        this.prenotazione.telefono_riferimento_vettore = ""
      }

      if(this.prenotazione.email_riferimento_vettore) {
        this.prenotazione.email_riferimento_vettore = ""
      }

    }
  },
  computed: {
    //  Filtro gli utenti del vettore in base al vettore scelto
    filteredUsers() {
      //  TODO da affinare in quanto se inserisco il nome di un vettore senza premere sul consigliato conta la scritta come solo testo
      if (typeof this.prenotazione.rag_soc_vettore == 'object' && this.prenotazione.rag_soc_vettore != null) {
        return this.prenotazione.rag_soc_vettore.users
      }
      return []
    },
    //  Mostro le fasce orarie del giorno scelto
    formattedFasciaOraria() {
      if (!this.prenotazione.date_picker || !this.date_valide) {
        return [];
      }

      var array = this.date_valide[this.prenotazione.date_picker]

      if (!array) {
        return [];
      }

      return array.map(el => {
        return {
          //text: `${el[0]} - ${el[1]}`,
          text: `${el[0]}`,
          value: el
        }
      })
    },
    formattedDataFascia() {
      var data = ""
      var fascia = ""

      if (!this.prenotazione.fascia) {
        return ""
      }
      
      data = new Date(this.prenotazione.date_picker).toLocaleDateString()
      fascia = `${this.prenotazione.fascia[0]} - ${this.prenotazione.fascia[1]}`

      return `${data} ${fascia}`
    }
  },
  watch: {
    //  Watcher per lanciare il download delle date al cambio mese
    date_watching_prop () {
      if (this.stepper == 2) {
        this.getDatePrenotazione().catch(() => {
          this.$store.state.snackbar_text = "Non è stato possibile scaricare le date disponibili per la prenotazione"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
        })
        this.prenotazione.date_picker = ''
      }
    },
    "prenotazione.rag_soc_vettore" () {
      if (!this.prenotazione.rag_soc_vettore) {
        this.prenotazione.nome_riferimento_vettore = ""
        this.prenotazione.telefono_riferimento_vettore = ""
        this.prenotazione.email_riferimento_vettore = ""
      }
    }
  },
};
</script>

<style scoped>
.fake-card-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
}
.fake-elevation {
  box-shadow: 0px -3px 1px -2px rgba(0, 0, 0, 0.2), 0px -2px 2px 0px rgba(0, 0, 0, 0.14), 0px -1px 5px 0px rgba(0, 0, 0, 0.12);
}
</style>