<template>
  <div>
    <v-card>
      <v-card-title class="pr-3">
        Prenotazione n. {{ prenotazione["id_prenotazione"] }} 
        <v-spacer/>

        <!-- Bottone di rinvio mail -->
        <v-tooltip top v-if="!edit && $store.state.permessi >= 8">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-blur
              color="warning"
              fab
              small
              :loading="mail_loading"
              :disabled="mail_loading"
              @click="sendMail"
              v-on="on"
              class="mr-2"
            >
              <v-icon>mdi-email</v-icon>
            </v-btn>
          </template>
          <span>Invia nuovamente e-mail</span>
        </v-tooltip>

        <!-- Bottone di modifica prenotazione -->
        <v-tooltip top v-if="!edit && $store.state.permessi >= 8">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-blur
              color="primary"
              fab
              small
              @click="editPrenotazione"
              v-on="on"
              class="mr-2"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Modifica prenotazione</span>
        </v-tooltip>
  
        <!-- Bottone di conferma modifica -->
        <v-tooltip top v-if="edit">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-blur
              color="success"
              fab
              small
              :loading="edit_loading"
              :disabled="edit_loading"
              @click="confermaModificaPrenotazione"
              v-on="on"
              class="mr-2"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </template>
          <span>Conferma modifica</span>
        </v-tooltip>
  
        <!-- Bottone per annullare modifica -->
        <v-tooltip top v-if="edit">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-blur
              color="error"
              fab
              small
              :disabled="edit_loading"
              @click="annullaModificaPrenotazione"
              v-on="on"
              class="mr-2"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Annulla modifica</span>
        </v-tooltip>
  
        <!-- Bottone di download file prenotazione -->
        <v-tooltip top v-if="!edit">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-blur
              color="primary"
              fab
              small
              :loading="file_loading"
              :disabled="file_loading"
              @click="getFilePrenotazione"
              v-on="on"
              class="mr-2"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Download documento</span>
        </v-tooltip>
  
        <!-- Bottone di annullamento prenotazione -->
        <v-tooltip top v-if="!edit && show_prenotazione_delete">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-blur
              color="primary"
              fab
              small
              @click="prenotazione_dialog_delete = true"
              v-on="on"
              class="mr-2"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Annulla prenotazione</span>
        </v-tooltip>
        
        <!-- Bottone di chiusura popup -->
        <v-btn
          v-if="!edit"
          v-blur
          icon
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="overflow: auto; max-height: calc(90vh - 72px);">
        <RiepilogoPrenotazione v-if="!edit" :prenotazione="prenotazione" class="pt-6"></RiepilogoPrenotazione>
        <ModificaPrenotazione v-else v-model="prenotazione_edit" class="pt-6"></ModificaPrenotazione>
      </v-card-text>
    </v-card>

    <v-dialog v-model="prenotazione_dialog_delete" max-width="500px" persistent>
      <v-card style="text-align: center;">
        <v-card-title class="text-h5" style="justify-content: center;">Attenzione</v-card-title>
        <v-card-text class="pb-0">
          <span class="black--text text-subtitle-1">Sei sicuro di voler annullare la prenotazione?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="secondary" 
            text 
            @click="prenotazione_dialog_delete = false"
          >
            Annulla
          </v-btn>
          <v-btn 
            color="primary" 
            text 
            @click="cancellaPrenotazione"
            :loading="prenotazione_dialog_delete_loading"
            :disabled="prenotazione_dialog_delete_loading"
            >
            Conferma
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    
<script>
  import RiepilogoPrenotazione from '../components/RiepilogoPrenotazione.vue'
  import ModificaPrenotazione from '../components/ModificaPrenotazione.vue'
  
  export default {
    data: () => ({
      prenotazione_edit: {},
      file_loading: false,
      mail_loading: false,
      edit_loading: false,
      edit: false,
      prenotazione_dialog_delete_loading: false,
      prenotazione_dialog_delete: false,
    }),
    props: ['prenotazione'],
    components: {
      RiepilogoPrenotazione,
      ModificaPrenotazione
    },
    mounted() {
    },
    methods: {
      //  Chiudo Dialog
      closeDialog() {
        this.$emit('close-dialog')
        setTimeout(() => {
          this.edit = false
        },200)
      },
      getFilePrenotazione() {
        this.file_loading = true

        this.$store.dispatch("openFilePrenotazione", {id_prenotazione: this.prenotazione.id_prenotazione})
        .catch((e) => {
          console.log(e)
          this.$store.state.snackbar_text = "Non è stato possibile scaricare il file"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
        })
        .finally(() => {
          this.file_loading = false
        })
      },
      editPrenotazione() {
        this.prenotazione_edit = Object.assign({}, this.prenotazione)
        this.$nextTick(() => {
          this.edit = true
        })
      },
      annullaModificaPrenotazione() {
        this.edit = false
      },
      confermaModificaPrenotazione() {
        this.edit_loading = true
        this.axios
          .put(`${this.$store.state.endpoint}/prenotazioni`, this.prenotazione_edit)
          .then(() => {
            //  Serve per aggiornare l'interfaccia immediatamente senza aspettare la chiamata che aggiorna tutte le prenotazioni
            this.prenotazione_edit.desc_stato = this.$store.state.stati_prenotazione.filter(el => el["id_stato"] == this.prenotazione_edit.stato)[0]["descrizione"]
            this.$emit('update-dialog-prenotazione', this.prenotazione_edit)

            this.$emit('update-prenotazioni')
            this.edit = false

            this.$store.state.snackbar = true
            this.$store.state.snackbar_text = "Prenotazione modificata!"
            this.$store.state.snackbar_color = "success"
          })
          .catch((e) => {
            this.$store.state.snackbar_text = "Non è stato possibile modificare la prenotazione"
            
            if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
              this.$store.state.snackbar_text += ": " + e.response.data.message
            }
            else {
              this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
            }
            
            this.$store.state.snackbar = true
            this.$store.state.snackbar_color = "error"
            console.log("Errore prenotazioni", e)
          })
          .finally(() => {
            this.edit_loading = false
          });
      },
      cancellaPrenotazione() {
        this.prenotazione_dialog_delete_loading = true
  
        var params = {
          id_prenotazione: this.prenotazione.id_prenotazione,
          stato: 0
        }
  
        this.axios
          .delete(`${this.$store.state.endpoint}/prenotazioni?${new URLSearchParams(params).toString()}`)
          .then(() => {
            this.$store.state.snackbar = true
            this.$store.state.snackbar_text = "Prenotazione annullata con successo!"
            this.$store.state.snackbar_color = "success"
  
            this.$emit('update-prenotazioni')
            this.closeDialog()
          })
          .catch((e) => {
            this.$store.state.snackbar_text = "Non è stato possibile annullare la prenotazione"

            if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
              this.$store.state.snackbar_text += ": " + e.response.data.message
            }
            else {
              this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
            }

            this.$store.state.snackbar = true
            this.$store.state.snackbar_color = "error"
            console.log("Errore tipo scarico", e)
          })
          .finally(() => {
            this.prenotazione_dialog_delete_loading = false
          });
      },
      sendMail() {
        this.mail_loading = true

        this.axios
          .post(`${this.$store.state.endpoint}/mail-prenotazione`, {
            id_prenotazione: this.prenotazione.id_prenotazione
          })
          .then(() => {
            this.$store.state.snackbar = true
            this.$store.state.snackbar_text = "E-mail inviata!"
            this.$store.state.snackbar_color = "success"
          })
          .catch((e) => {
            this.$store.state.snackbar_text = "Non è stato possibile inviare l'e-mail"

            if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
              this.$store.state.snackbar_text += ": " + e.response.data.message
            }
            else {
              this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
            }

            this.$store.state.snackbar = true
            this.$store.state.snackbar_color = "error"
            console.log("Errore prenotazioni", e)
          })
          .finally(() => {
            this.mail_loading = false
          });
      }
    },
    computed: {
      //  Mostro il bottone solo se prima della data di cutoff
      show_prenotazione_delete() {
        //  Rimuovo dalla data le ore di preavviso
        var d = new Date(`${this.prenotazione.data} ${this.prenotazione.ora_inizio}`)
        d.setHours(d.getHours() - this.prenotazione.ora_max_disdetta)

        return new Date() <= d;
      }
    },
  };
</script>