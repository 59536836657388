<template>
  <div
    style="display: flex; align-items: center; justify-content: center; height: 100%;"
  >
    <v-card
      class="mx-auto"
      min-width="300px"
      max-width="600px"
      width="55vw"
      style="display: block; text-align: left"
    >
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="impostaPsw">
        <v-card-title class="d-flex justify-center pb-8">
          Imposta Password
        </v-card-title>
        <v-card-subtitle style="text-align: center;">
          La password con cui hai effettuato l'accesso è provvisoria. <br>Inserisci una nuova password definitiva per accedere alle funzionalità del portale.
        </v-card-subtitle>
        <v-card-text>
          <v-text-field 
            label="Password"
            v-model="password"
            :append-icon="show_psw ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show_psw ? 'text' : 'password'"
            @click:append="show_psw = !show_psw"
            :rules="obbligatorio_roule_min_6"
            :maxlength="20"
            counter="20"
          />
          <v-text-field 
            label="Conferma Password"
            v-model="conferma_password"
            :append-icon="show_psw2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show_psw2 ? 'text' : 'password'"
            @click:append="show_psw2 = !show_psw2"
            :rules="[...obbligatorio_roule_min_6, psw_uguali_rule]"
            :maxlength="20"
            counter="20"
          />
        </v-card-text>
        <v-card-actions
          class="pa-4 pt-0 mb-2"
          style="display: block; text-align: center"
        >
          <v-btn
            color="primary"
            width="100%"
            max-width="300px"
            :disabled="!valid || loading"
            :loading="loading"
            type="submit"
          >
            Conferma
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import router from "../router";

export default {
  data: () => ({
    valid: false,
    loading: false,
    show_psw: false,
    show_psw2: false,
    password: "",
    conferma_password: "",
    obbligatorio_roule_min_6: [
      v => !!v || 'Campo obbligatorio',
      v => v.length > 5 || 'Minimo 6 caratteri'
    ],
  }),
  mounted() {
  },
  methods: {
    impostaPsw() {
      this.$refs.form.validate()
      this.$nextTick(() => {
        if(!this.valid) {
          return
        }

        this.loading = true

        this.axios
          .post(`${this.$store.state.endpoint}/imposta-psw`, {
            password: this.password
          })
          .then(() => {
            this.$store.state.snackbar = true
            this.$store.state.snackbar_text = "Password modifica con successo. Effettua nuovamente il login per procedere"
            this.$store.state.snackbar_color = "success"

            localStorage.setItem("jwt", null)
            router.push("/")
          })
          .catch((e) => {
            this.loading = false

            this.$store.state.snackbar_text = "Non è stato possibile modificare la password"

            if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
              this.$store.state.snackbar_text += ": " + e.response.data.message
            }
            else {
              this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
            }

            this.$store.state.snackbar = true
            this.$store.state.snackbar_color = "error"
            console.log("Errore utenza", e)
          })
      })
    },
    psw_uguali_rule(){
      return this.password == this.conferma_password ? true : "Le password inserite sono diverse!"
    }
  },
}
</script>

<style scoped>
</style>