<template>
    <v-container class="fill-height py-0" fluid>
      <v-row class="fill-height">
        <v-col class="pa-0">
          <v-sheet height="64">
            <v-toolbar
              flat
              height="60"
              class="pt-1"
            >
              <v-btn
                v-blur
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Oggi
              </v-btn>
              <!-- Bottone di selezione del tipo di calendario -->
              <v-menu
                bottom
                right
                v-if="$store.state.permessi >= 8"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ tipi_calendario[$store.state.tipo_calendario] }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="$store.state.tipo_calendario = 'category'">
                    <v-list-item-title>Comparti</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$store.state.tipo_calendario = 'week'">
                    <v-list-item-title>Settimana</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                v-blur
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
              >
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn
                v-blur
                fab
                text
                small
                color="grey darken-2"
                @click="next"
              >
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-toolbar-title class="d-flex align-center" v-if="$refs.calendar">
                {{ $store.state.tipo_calendario == 'category' ? `${calendar_date.day} ${mesi[calendar_date.month - 1]} ${calendar_date.year}` : $refs.calendar.title }}

                <!-- Date picker per scegliere la data -->
                <v-menu
                  v-model="date_picker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="calendar"
                    @input="date_picker = false;"
                    locale="it-IT"
                    color="secondary"
                    header-color="primary"
                    first-day-of-week="1"
                    show-adjacent-months
                  >
                  </v-date-picker>
                </v-menu>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                v-blur
                fab
                small
                color="primary"
                @click="openAggiungiPrenotazione"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-toolbar>
            <v-progress-linear
              indeterminate
              color="primary"
              v-if="loading"
            ></v-progress-linear>
          </v-sheet>
          <v-sheet style="height: calc(100vh - 64px - 64px)"> <!-- All'altezza della pagina sottraggo la navbar e la toolbar per navigare nel portale-->
            <v-calendar
              ref="calendar"
              v-model="calendar"
              color="primary"
              :type="$store.state.tipo_calendario == '' ? 'week' : $store.state.tipo_calendario"
              :first-time="$store.getters.getFirstTime"
              :interval-count="$store.getters.getIntervalCount"
              :interval-minutes="$store.getters.getIntervalMinutes"
              locale="it-IT"
              :weekdays="weekday"
              :short-weekdays="false"
              :short-intervals="false"
              category-show-all
              :categories="$store.getters.getCategoriesList"
              :events="prenotazioni"
              :event-color="getEventColor"
              :event-overlap-threshold="30"
              event-more
              event-more-text="altri..."
              @change="updatePrenotazioni"
              @click:event="showEventPopup"
            >
              <template v-slot:day-body="{ date, week }">
                <div
                  class="v-current-time"
                  :class="{ first: date === week[0].date }"
                  :style="{ top: nowY }"
                  v-if="$store.state.tipo_calendario == 'week'"
                ></div>
              </template>
              <template v-slot:event="{ event }">
                <span class="in-event pa-1" :style="event['opaco'] == 1 ? 'color: rgb(60,64,67,0.6)' : '' ">
                  <strong>{{ event['opaco'] == 1 ? `(${event["desc_stato"]}) `:"" }}Prenotazione n. {{ event["id_prenotazione"] }}</strong><br>
                  <span v-if="![2,4].includes($store.state.permessi)">{{ event["rag_soc_cliente"] }}<br></span>
                  <span v-if="![1,3].includes($store.state.permessi)">{{ event["rag_soc_vettore"] }}<br></span>
                  <span v-if="$store.state.permessi >= 8">{{ event["tipo_scarico"] }}<br></span>
                  <span>{{ event["ora_inizio"] }} - {{ event["ora_fine"] }}</span>
                </span>
              </template>
            </v-calendar> <!--@mouseenter:event="showEventPopup"-->
          </v-sheet>
        </v-col>
      </v-row>
      <v-dialog v-model="aggiungi_prenotazione_dialog" max-width="1000px" persistent scrollable>
        <AggiungiPrenotazione ref="aggiungi_prenotazione" @close-dialog="aggiungi_prenotazione_dialog = false" @update-prenotazioni="updatePrenotazioni()"/>
      </v-dialog>
      <v-dialog v-model="mostra_prenotazione_dialog" max-width="1000px" persistent scrollable>
        <MostraPrenotazione ref="mostra_prenotazione" :prenotazione="dialog_prenotazione" @close-dialog="mostra_prenotazione_dialog = false" @update-prenotazioni="updatePrenotazioni()" @update-dialog-prenotazione="updateDialogPrenotazione"/>
      </v-dialog>
    </v-container>
</template>

<script>
  import AggiungiPrenotazione from '../components/AggiungiPrenotazione.vue'
  import MostraPrenotazione from '../components/MostraPrenotazione.vue'

  export default {
    data: () => ({
      loading: false,
      weekday: [ 1, 2, 3, 4, 5, 6, 0 ],
      mesi: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
      tipi_calendario: {category: "Comparti", week: "Settimana"},
      date_picker: false, 
      calendar: '',
      calendar_date: null,
      events: [],
      prenotazioni: [],
      aggiungi_prenotazione_dialog: false,
      mostra_prenotazione_dialog: false,
      dialog_prenotazione: null,
      promise_clienti: null,
      promise_vettori: null,
      promise_scarichi: null,
      promise_comparti: null,
      promise_stati: null,
      refreshInterval: null,
      updateTimeInterval: null
    }),
    components: {
      AggiungiPrenotazione,
      MostraPrenotazione
    },
    created() {
      //  Se nello store è vuoto vuol dire che o è stato fatto il refresh o è un nuovo login quindi imposto il default
      if (this.$store.state.tipo_calendario == "") {
        this.$store.state.tipo_calendario = this.$store.state.permessi >= 8 ? 'category' : 'week'
      }
    },
    mounted () {
      this.$store.state.loaded = false

      this.reloadDati()
      this.calendar = this.$store.getters.getCurrentDate

      Promise.all([this.promise_clienti, this.promise_vettori, this.promise_scarichi, this.promise_comparti])
      .then(() => {
        this.$store.state.loaded = true
      })

      //  Funzione per aggiornare la barra rossa del calendario
      this.updateTime()

      this.refreshInterval = setInterval(() => {this.reloadDati()}, 300000)  //  Ogni 5 min aggiorno i dati
    },
    methods: {
      reloadDati() { 
        this.loading = true

        this.promise_clienti = this.$store.dispatch("getClienti").catch((e) => {console.log(e)})
        this.promise_vettori = this.$store.dispatch("getVettori").catch((e) => {console.log(e)})
        this.promise_scarichi = this.$store.dispatch("getTipiScarico").catch((e) => {console.log(e)})
        this.promise_comparti = this.$store.dispatch("getComparti").catch((e) => {console.log(e)})
        this.promise_stati = this.$store.dispatch("getStatiPrenotazione").catch((e) => {console.log(e)})

        this.updatePrenotazioni();

      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.calendar = this.$store.getters.getCurrentDate
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      updatePrenotazioni() {
        var start = this.$refs.calendar.lastStart.date
        var end = this.$refs.calendar.lastEnd.date

        this.calendar_date = this.$refs.calendar.lastStart

        //  Calendario di un giorno se admin
        if (this.$store.state.tipo_calendario == 'category') {
          end = start
        }

        if (this.promise_clienti != null && this.promise_vettori != null && this.promise_scarichi != null && this.promise_comparti != null && this.promise_stati != null) {
          Promise.all([this.promise_clienti, this.promise_vettori, this.promise_scarichi, this.promise_comparti, this.promise_stati])
          .then(() => {
            this.getPrenotazioni(start, end);
          })
        }
      },
      showEventPopup({ nativeEvent, event }) {
        this.updateDialogPrenotazione(event)
        this.mostra_prenotazione_dialog = true
      },
      openAggiungiPrenotazione() {
        this.aggiungi_prenotazione_dialog = true
        this.$nextTick(() => {
          this.$refs.aggiungi_prenotazione.resetForm()
        })
      },
      getPrenotazioni(start, end) {
        this.loading = true

        var params = {
          da: start,
          a: end
        }

        this.axios
          .get(`${this.$store.state.endpoint}/prenotazioni?${new URLSearchParams(params).toString()}`)
          .then((response) => {
            this.prenotazioni = response.data.map((el) => {
              // if (this.$store.state.permessi == 1 || this.$store.state.permessi == 3) {
              //   el["name"] = `Prenotazione n.${el["id_prenotazione"]} - ${el["rag_soc_cliente"]}`;
              // }
              // else if (this.$store.state.permessi == 2 || this.$store.state.permessi == 4) {
              //   el["name"] = `Prenotazione n.${el["id_prenotazione"]} - ${el["rag_soc_vettore"]}`;
              // }
              // else {
              //   el["name"] = `Prenotazione n.${el["id_prenotazione"]} - ${el["rag_soc_cliente"]} - ${el["rag_soc_vettore"]}`;
              // }

              //  Opacizzo colore della prenotazione
              if (el["opaco"]) {
                var colore = el["color"]

                if (colore.length > 7) {
                  colore = colore.substr(0, 7)
                }

                var rgb = this.hexToRgb(colore)
                el["color"] = this.RGB_Linear_Shade(0.6, `rgb(${rgb["r"]}, ${rgb["g"]}, ${rgb["b"]})`)
              }

              el["start"] = new Date(`${el["data"]} ${el["ora_inizio"]}`)
              el["end"] = new Date(`${el["data"]} ${el["ora_fine"]}`)
              el["timed"] = true

              //  Aggiorno il dialog di visualizzazione della prenotazione se aperto
              if (this.dialog_prenotazione && this.dialog_prenotazione.id_prenotazione == el.id_prenotazione) {
                this.updateDialogPrenotazione(el)
              }

              return el
            });

            this.loading = false
          })
          .catch((e) => {
            this.$store.state.snackbar_text = "Non è stato possibile scaricare i dati relativi alle prenotazioni"

            if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
              this.$store.state.snackbar_text += ": " + e.response.data.message
            }

            this.$store.state.snackbar = true
            this.$store.state.snackbar_color = "error"
            this.loading = false
            console.log("Errore prenotazioni", e)
          });
      },
      updateTime() {
        this.updateTimeInterval = setInterval(() => {
          if (this.cal) {
            this.cal.updateTimes()
          }
        }, 60 * 1000)
      },
      updateDialogPrenotazione(el) {
        this.dialog_prenotazione = el
      },
      // https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
      RGB_Linear_Shade(p,c) {
        var i=parseInt,r=Math.round,[a,b,c,d]=c.split(","),P=p<0,t=P?0:255*p,P=P?1+p:1-p;
        return"rgb"+(d?"a(":"(")+r(i(a[3]=="a"?a.slice(5):a.slice(4))*P+t)+","+r(i(b)*P+t)+","+r(i(c)*P+t)+(d?","+d:")");
      },
      // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
      hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
          return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
    },
    computed: {
      cal () {
        return !this.loading ? this.$refs.calendar : null
      },
      nowY () {
        return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
      },
    },
    beforeDestroy() {
      clearInterval(this.refreshInterval)
      clearInterval(this.updateTimeInterval)
    }
  }
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
.in-event {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}
</style>