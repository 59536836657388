import Vue from 'vue'
import Vuex from 'vuex'
import vuetify from '../plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ambiente: 1,
    endpoint: "https://prenotazioni.calonigroupage.com/api",
    //  endpoint: "http://localhost:5000/api",
    nome_user: "",
    username_user: "",
    telefono_user: "",
    jwt: "",
    clienti: [],
    vettori: [],
    tipi_scarico: [],
    comparti: [],
    stati_prenotazione: [],
    date_chiusura: [],
    logo: require("../assets/Newton-Logo.png"),
    loaded: true,
    permessi: 0,
    inizio_fascia_1: "00:00:00",
    fine_fascia_1: "00:00:00",
    inizio_fascia_2: "00:00:00",
    fine_fascia_2: "00:00:00",
    ampiezza_fascia: 0,
    snackbar: false,
    snackbar_text: "",
    snackbar_color: "primary",
    tipo_calendario: "",
    //  Serve per limitare i minuti dei timepicker
    steps_time_picker: m => m % 15 === 0,
    //  Lo so, non li ho messi in ordine...
    permessi_array: [
      {id: 1, text: "Vettore"}, 
      {id: 3, text: "Vettore Master"}, 
      {id: 2, text: "Cliente"}, 
      {id: 4, text: "Cliente Master"}, 
      {id: 8, text: "Admin"}, 
    ]
  },
  getters: {
    //  Ritorno l'orario minore dal quale far partire il calendario
    getFirstTime: (state) => {
      var int_fascia_1 = parseInt(state.inizio_fascia_1.replace(":",""))
      var int_fascia_2 = parseInt(state.inizio_fascia_2.replace(":",""))

      return int_fascia_1 < int_fascia_2 ? state.inizio_fascia_1 : state.inizio_fascia_2
    },
    //  Ritorno l'orario minore dal quale far partire il calendario
    getLastTime: (state) => {
      var int_fascia_1 = parseInt(state.fine_fascia_1.replace(":",""))
      var int_fascia_2 = parseInt(state.fine_fascia_2.replace(":",""))

      return int_fascia_1 > int_fascia_2 ? state.fine_fascia_1 : state.fine_fascia_2
    },
    //  Calcolo quante fasce mostrare nel calendario - PER ORA METTO FISSO PER ESTETICA
    getIntervalCount: (state, getters) => {
      var int_inizio = parseInt(getters.getFirstTime)
      var int_fine = parseInt(getters.getLastTime)

      var delta_ore = int_fine - int_inizio

      //  return delta_ore / parseFloat(ampiezza_fascia)
      return delta_ore / 0.5
    },
    //  Durata di ogni fascia del calendario - PER ORA METTO FISSO PER ESTETICA
    getIntervalMinutes: (state) => {
      //  return parseFloat(ampiezza_fascia) * 60
      return 30
    },
    getCurrentDate: () => {
      var date = new Date()
      return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
    },
    getCategoriesList: (state) => {
      return state.comparti.map(el => el.codice)
    },
    getClientiAttivi: (state) => {
      return state.clienti.filter(el => el.stato == 1)
    },
    getVettoriAttivi: (state) => {
      return state.vettori.filter(el => el.stato == 1)
    },
    getTipiScaricoAttivi: (state) => {
      return state.tipi_scarico.filter(el => el.stato == 1)
    },
    getCompartiAttivi: (state) => {
      return state.comparti.filter(el => el.stato == 1)
    },
    getStatiPrenotazioneAttivi: (state) => {
      return state.stati_prenotazione.filter(el => el.stato == 1)
    },
  },
  mutations: {
    reset(state) {
      state.nome_user = ""
      state.username_user = ""
      state.telefono_user = ""
      state.jwt = ""
      state.clienti = []
      state.vettori = []
      state.tipi_scarico = []
      state.comparti = []
      state.stati_prenotazione = []
      state.date_chiusura = []
      state.tipo_calendario = ""
    }
  },
  actions: {
    openFilePrenotazione({commit, state}, params) {
      return new Promise ((resolve, reject) => {
        axios
          .get(`${state.endpoint}/pdf?${new URLSearchParams(params).toString()}`, {responseType: 'blob'})
          .then((response) => {
            window.open(URL.createObjectURL(response.data));
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    getAmbiente({commit, state}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${state.endpoint}/ambiente`)
          .then((response) => {
            state.logo = response.data.logo
            state.inizio_fascia_1 = response.data.inizio_fascia_1 ? response.data.inizio_fascia_1 : ""
            state.fine_fascia_1 = response.data.fine_fascia_1 ? response.data.fine_fascia_1 : ""
            state.inizio_fascia_2 = response.data.inizio_fascia_2 ? response.data.inizio_fascia_2 : ""
            state.fine_fascia_2 = response.data.fine_fascia_2 ? response.data.fine_fascia_2 : ""
            state.ampiezza_fascia = response.data.ampiezza_fascia ? response.data.ampiezza_fascia : ""
            
            vuetify.framework.theme.themes.light.primary = response.data.colore_primario;
            vuetify.framework.theme.themes.light.secondary = response.data.colore_secondario;

            resolve();
          })
          .catch((e) => {
            console.log(e)
            reject();
          });
      })
    },
    getClienti({commit, state}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${state.endpoint}/clienti`)
          .then((response) => {
            state.clienti = response.data;
            resolve()
          })
          .catch((e) => {
            state.snackbar = true
            state.snackbar_text = "Non è stato possibile scaricare i dati relativi ai clienti"
            state.snackbar_color = "error"
            console.log("Errore clienti", e)
            reject()
          });
      })
    },
    getVettori({commit, state}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${state.endpoint}/vettori`)
          .then((response) => {
            state.vettori = response.data;
            resolve()
          })
          .catch((e) => {
            state.snackbar = true
            state.snackbar_text = "Non è stato possibile scaricare i dati relativi ai vettori"
            state.snackbar_color = "error"
            console.log("Errore vettori", e)
            reject()
          });
      })
    },
    getTipiScarico({commit, state}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${state.endpoint}/tipi-scarico`)
          .then((response) => {
            state.tipi_scarico = response.data;
            resolve()
          })
          .catch((e) => {
            state.snackbar = true
            state.snackbar_text = "Non è stato possibile scaricare i dati relativi ai tipi di scarico"
            state.snackbar_color = "error"
            console.log("Errore tipi scarico", e)
            reject()
          });
      })
    },
    getComparti({commit, state}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${state.endpoint}/comparti`)
          .then((response) => {
            state.comparti = response.data;
            resolve()
          })
          .catch((e) => {
            state.snackbar = true
            state.snackbar_text = "Non è stato possibile scaricare i dati relativi ai comparti"
            state.snackbar_color = "error"
            console.log("Errore comparti", e)
            reject()
          });
      })
    },
    getStatiPrenotazione({commit, state}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${state.endpoint}/stati-prenotazione`)
          .then((response) => {
            state.stati_prenotazione = response.data.map(el => {
              el["opaco"] = el["opaco"] == 1
              return el
            });
            resolve()
          })
          .catch((e) => {
            state.snackbar = true
            state.snackbar_text = "Non è stato possibile scaricare i dati relativi agli stati della prenotazione"
            state.snackbar_color = "error"
            console.log("Errore stati", e)
            reject()
          });
      })
    },
    getDateChiusura({commit, state}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${state.endpoint}/date-chiusura`)
          .then((response) => {
            state.date_chiusura = response.data
            resolve()
          })
          .catch((e) => {
            state.snackbar = true
            state.snackbar_text = "Non è stato possibile scaricare i dati relativi alle date di chiusura"
            state.snackbar_color = "error"
            console.log("Errore date chiusura", e)
            reject()
          });
      })
    },
  },
  modules: {
  }
})
