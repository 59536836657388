<!--TODO DEVI METTERE I CONTROLLI PER LE FASCE ORARIE-->
<template>
  <v-card>
    <v-card-title>{{ value.nuovo_tipo_scarico ? "Crea Tipo Scarico" : "Modifica Tipo Scarico" }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field 
              label="Descrizione"
              v-model="value.descrizione"
              :rules="obbligatorio_roule"
              :maxlength="40"
              counter="40"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field 
              label="Giorni preavviso"
              v-model="value.giorni_preavviso"
              :rules="[...obbligatorio_roule, ...solo_numeri_roule]"
              :maxlength="2"
              counter="2"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu_ora_cutoff"
              v-model="menu_ora_cutoff"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="value.ora_cutoff"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.ora_cutoff"
                  label="Ora cutoff"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="obbligatorio_roule"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu_ora_cutoff"
                v-model="value.ora_cutoff"
                full-width
                @click:minute="$refs.menu_ora_cutoff.save(value.ora_cutoff)"
                format="24hr"
                :allowed-minutes="$store.state.steps_time_picker"
                scrollable
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field 
              label="Ore preavviso disdetta"
              v-model="value.ora_max_disdetta"
              :rules="[...zero_obbligatorio_roule, ...solo_numeri_roule]"
              suffix="ore"
              :maxlength="3"
              counter="3"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu_inizio_fascia_1"
              v-model="menu_inizio_fascia_1"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="value.inizio_fascia_1"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.inizio_fascia_1"
                  label="Inizio fascia 1"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="obbligatorio_roule"
                  clearable
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu_inizio_fascia_1"
                v-model="value.inizio_fascia_1"
                full-width
                @click:minute="$refs.menu_inizio_fascia_1.save(value.inizio_fascia_1)"
                format="24hr"
                :min="$store.getters.getFirstTime"
                :max="value.fine_fascia_1 ? value.fine_fascia_1 : $store.getters.getLastTime"
                :allowed-minutes="$store.state.steps_time_picker"
                scrollable
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu_fine_fascia_1"
              v-model="menu_fine_fascia_1"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="value.fine_fascia_1"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.fine_fascia_1"
                  label="Fine fascia 1"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="obbligatorio_roule"
                  clearable
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu_fine_fascia_1"
                v-model="value.fine_fascia_1"
                full-width
                @click:minute="$refs.menu_fine_fascia_1.save(value.fine_fascia_1)"
                format="24hr"
                :min="value.inizio_fascia_1 ? value.inizio_fascia_1 : $store.getters.getFirstTime"
                :max="$store.getters.getLastTime"
                :allowed-minutes="$store.state.steps_time_picker"
                scrollable
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu_inizio_fascia_2"
              v-model="menu_inizio_fascia_2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="value.inizio_fascia_2"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.inizio_fascia_2"
                  label="Inizio fascia 2"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="value.fine_fascia_2 ? obbligatorio_roule : []"
                  clearable
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu_inizio_fascia_2"
                v-model="value.inizio_fascia_2"
                full-width
                @click:minute="$refs.menu_inizio_fascia_2.save(value.inizio_fascia_2)"
                format="24hr"
                :min="$store.getters.getFirstTime"
                :max="value.fine_fascia_2 ? value.fine_fascia_2 : $store.getters.getLastTime"
                :allowed-minutes="$store.state.steps_time_picker"
                scrollable
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu_fine_fascia_2"
              v-model="menu_fine_fascia_2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="value.fine_fascia_2"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.fine_fascia_2"
                  label="Fine fascia 2"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="value.inizio_fascia_2 ? obbligatorio_roule : []"
                  clearable
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu_fine_fascia_2"
                v-model="value.fine_fascia_2"
                full-width
                @click:minute="$refs.menu_fine_fascia_2.save(value.fine_fascia_2)"
                format="24hr"
                :min="value.inizio_fascia_2 ? value.inizio_fascia_2 : $store.getters.getFirstTime"
                :max="$store.getters.getLastTime"
                :allowed-minutes="$store.state.steps_time_picker"
                scrollable
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="pb-3">
              <v-text-field 
                label="Max scarichi giorno"
                v-model="value.max_scarichi_giorno"
                :rules="[...obbligatorio_roule, ...solo_numeri_roule]"
                :maxlength="3"
                counter="3"
              />
            </div>
            <div class="py-3">
              <v-text-field 
                label="Contemporaneità"
                v-model="value.max_scarichi_fascia"
                :rules="[...obbligatorio_roule, ...solo_numeri_roule]"
                :maxlength="2"
                counter="2"
              />
            </div>
            <div class="pt-3">
              <v-text-field 
                label="Durata scarico"
                v-model="value.durata_scarico"
                :rules="[...obbligatorio_roule, ...solo_numeri_roule]"
                suffix="ore"
                :maxlength="2"
                counter="2"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex justify-center">
            <v-color-picker
              v-model="value.colore"
              dot-size="25"
              swatches-max-height="200"
              hide-mode-switch
              mode="hexa"
            ></v-color-picker>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="secondary"
        text
        @click="closeDialog"
      >
        Annulla
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="saveTipoScarico"
        :disabled="loading || !valid"
        :loading="loading"
      >
        Salva
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    loading: false,
    menu_ora_cutoff: false,
    menu_inizio_fascia_1: false,
    menu_fine_fascia_1: false,
    menu_inizio_fascia_2: false,
    menu_fine_fascia_2: false,
    obbligatorio_roule: [v => !!v || 'Campo obbligatorio!'],
    zero_obbligatorio_roule: [v => !!v || v == 0 || 'Campo obbligatorio'],
    solo_numeri_roule: [
      v => !v || !isNaN(v) || 'Inserire un numero valido!',
      v => !v || !v.includes('.') || 'Il campo consente solo numeri!',
      v => !v || !v.includes(',') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('-') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('+') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('e') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('E') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('/') || 'Il campo consente solo numeri!'
    ],
    edit_rules_email: [v => !v || /.+@.+\..+/.test(v) || 'E-mail non valida!'],
  }),
  props: ['value'],
  methods: {
    closeDialog() {
      this.$emit("close-dialog")
      this.$refs.form.reset()
    },
    saveTipoScarico() {
      this.$refs.form.validate()
      this.$nextTick(() => {
        if(!this.valid) {
          return
        }

        if (this.value.nuovo_tipo_scarico) {
          this.creaTipoScarico()
        }
        else {
          this.modificaTipoScarico()
        }
      })
    },
    creaTipoScarico() {
      this.loading = true

      this.axios
        .post(`${this.$store.state.endpoint}/tipi-scarico`, {
          descrizione: this.value.descrizione,
          giorni_preavviso: this.value.giorni_preavviso,
          ora_cutoff: this.value.ora_cutoff,
          ora_max_disdetta: this.value.ora_max_disdetta,
          durata_scarico: this.value.durata_scarico,
          inizio_fascia_1: this.value.inizio_fascia_1,
          fine_fascia_1: this.value.fine_fascia_1,
          inizio_fascia_2: this.value.inizio_fascia_2 ? this.value.inizio_fascia_2 : null,
          fine_fascia_2: this.value.fine_fascia_2 ? this.value.fine_fascia_2 : null,
          max_scarichi_giorno: this.value.max_scarichi_giorno,
          max_scarichi_fascia: this.value.max_scarichi_fascia,
          colore: this.value.colore
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Tipo scarico creato con successo!"
          this.$store.state.snackbar_color = "success"

          this.$emit("update-tipi-scarico")
          this.closeDialog()
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile creare il tipo scarico"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore tipo scarico", e)
        })
        .finally(() => {
          this.loading = false
        });
    },
    modificaTipoScarico() {
      this.loading = true

      this.axios
        .put(`${this.$store.state.endpoint}/tipi-scarico`, {
          id_tipo_scarico: this.value.id_tipo_scarico,
          descrizione: this.value.descrizione,
          giorni_preavviso: this.value.giorni_preavviso,
          ora_cutoff: this.value.ora_cutoff,
          ora_max_disdetta: this.value.ora_max_disdetta,
          durata_scarico: this.value.durata_scarico,
          inizio_fascia_1: this.value.inizio_fascia_1,
          fine_fascia_1: this.value.fine_fascia_1,
          inizio_fascia_2: this.value.inizio_fascia_2 ? this.value.inizio_fascia_2 : null,
          fine_fascia_2: this.value.fine_fascia_2 ? this.value.fine_fascia_2 : null,
          max_scarichi_giorno: this.value.max_scarichi_giorno,
          max_scarichi_fascia: this.value.max_scarichi_fascia,
          colore: this.value.colore
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Tipo scarico modificato con successo!"
          this.$store.state.snackbar_color = "success"

          this.$emit("update-tipi-scarico")
          this.closeDialog()
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile modificare il tipo scarico"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore tipo scarico", e)
        })
        .finally(() => {
          this.loading = false
        });
    }
  }
}
</script>

<style>

</style>