<template>
    <v-container class="fill-height pa-0" fluid>
      <v-data-table
        ref="data_table"
        :headers="headers"
        :items="$store.state.vettori"
        style="width:100%"
        height="calc(100vh - 200px)"
        :items-per-page="15"
        :search="search"
        no-results-text="Nessun risultato"
        fixed-header
        :footer-props="{
          'items-per-page-options': [15, 25, 50, -1],
          'items-per-page-text': 'Elementi per pagina',
          'items-per-page-all-text': 'Tutti',
        }"
        class="mt-3"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              label="Cerca"
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
            ></v-text-field>
  
            <v-spacer></v-spacer>
  
            <v-btn
              v-blur
              fab
              small
              color="primary"
              @click="openAggiungiVettore"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.clienti`]="{ item }">
          {{ getFormattedClienti(item.clienti) }}
        </template>
  
        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="item.stato == 1">
            <v-icon small class="mr-2" @click="openModificaVettore(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="openModificaStatoVettore(item, 2)"> 
              mdi-cancel
            </v-icon>
            <v-icon small @click="openModificaStatoVettore(item, 0)"> 
              mdi-delete 
            </v-icon>
          </div>
          <div v-else>
            <v-btn
              color="grey lighten-2"
              @click="openModificaStatoVettore(item, 1)"
              elevation="0"
            >
              Attiva
            </v-btn>
          </div>
        </template>
      </v-data-table>
  
      <v-dialog v-model="vettore_dialog" max-width="800px" persistent>
        <AggiungiModificaVettore v-model="vettore_edit" ref="vettore_dialog" @close-dialog="closeDialog" @update-vettori="updateClientiVettori()"/>
      </v-dialog>
  
      <v-dialog v-model="vettore_dialog_delete" max-width="500px" persistent>
        <v-card style="text-align: center;">
          <v-card-title class="text-h5" style="justify-content: center;">Attenzione</v-card-title>
          <v-card-text class="pb-0">
            <span class="black--text text-subtitle-1">
              Sei sicuro di voler {{ vettore_delete["stato"] == 2 ? "disabilitare" : vettore_delete["stato"] == 1 ? "abilitare" : "cancellare" }} il vettore <b>{{ vettore_delete["ragione_sociale"] }}</b>?
            </span>
            <br>
            <span v-if="vettore_delete['stato'] == 0" class="black--text text-subtitle-1">
              Verranno inoltre cancellate le relative utenze.
            </span>
            <span v-if="vettore_delete['stato'] == 2" class="black--text text-subtitle-1">
              Tutte le utenze del vettore verranno disabilitate.
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              color="secondary" 
              text 
              @click="closeDialog"
            >
              Annulla
            </v-btn>
            <v-btn 
              color="primary" 
              text 
              @click="modificaStatoVettore"
              :loading="vettore_dialog_delete_loading"
              :disabled="vettore_dialog_delete_loading"
              >
              Conferma
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import AggiungiModificaVettore from '../components/AggiungiModificaVettore.vue'
  
  export default {
    data: () => ({
      search: "",
      loading: false,
      vettore_dialog: false,
      vettore_dialog_delete: false,
      vettore_dialog_delete_loading: false,
      vettore_edit: {},
      vettore_delete: {},
      vettore_edit_default: {
        id_cliente: null,
        ragione_sociale: "",
        tipo: 2,
        clienti: [],
        data_ultima_modifica: "",
        user_ultima_modifica: "",
        stato: ""
      },
    }),
    computed: {
      headers(){
        return [
          {
            text: "Ragione Sociale",
            value: "ragione_sociale",
          },
          {
            text: "Clienti associati",
            value: "clienti"
          },
          { text: "Azioni", value: "actions", sortable: false, align: "center"},
        ]
      } 
    },
    components: {
      AggiungiModificaVettore
    },
    mounted() {
      this.loading = true;
      var promise_clienti = null
      var promise_comparti = null

      if (!this.$store.state.comparti || this.$store.state.comparti.length == 0) {
        promise_comparti = this.$store.dispatch("getComparti")
      }
      if (!this.$store.state.clienti || this.$store.state.clienti.length == 0) {
        promise_clienti = this.$store.dispatch("getClienti")
      }

      Promise.all([promise_clienti, promise_comparti]).then(() => {
        if (!this.$store.state.vettori || this.$store.state.vettori.length == 0) {
          this.$store.dispatch("getVettori").finally(() => {
            this.loading = false
          })
        }
        else {
          this.$refs.data_table.$forceUpdate();
          this.loading = false;
        }
      })
    },
    methods: {
      updateClientiVettori() {
        this.$store.dispatch('getVettori')
        this.$store.dispatch("getClienti")
      },
      closeDialog() {
        this.vettore_dialog = false
        this.vettore_dialog_delete = false
        this.vettore_edit = Object.assign({}, this.vettore_edit_default)
        this.vettore_delete = Object.assign({}, this.vettore_edit_default)
      },
      openAggiungiVettore() {
        this.vettore_edit = Object.assign({}, this.vettore_edit_default)
        this.vettore_edit["nuovo_vettore"] = true
        this.vettore_dialog = true
      },
      openModificaVettore(item) {
        for (var prop in item) {
          if (Object.prototype.hasOwnProperty.call(item, prop) && item[prop]) {
            //  A volte questo parametro viene passato come testo se c'è una singola occorrenza
            if(prop == "clienti") {
              if (!Array.isArray(item[prop])){
                item[prop] = [item[prop]]
              }
            }
            else {
              item[prop] = `${item[prop]}`
            }
          }
        }

        this.vettore_edit = Object.assign({}, item)
        this.vettore_edit["nuovo_vettore"] = false

        //  Trasformo gli id in oggetti per precompilare la combobox
        if(this.vettore_edit["clienti"] && Array.isArray(this.vettore_edit["clienti"])) {
          this.vettore_edit["clienti"] = this.vettore_edit["clienti"].map((el) => this.getClienteFromId(el))
        }

        this.vettore_dialog = true
      },
      openModificaStatoVettore(item, stato) {
        this.vettore_delete = Object.assign({}, item)
        this.vettore_delete["stato"] = stato
        this.vettore_dialog_delete = true
      },
      modificaStatoVettore() {
        this.vettore_dialog_delete_loading = true
  
        var params = {
          id_vettore: this.vettore_delete.id_cliente,
          stato: this.vettore_delete.stato
        }

        var dicitura = ""
        switch (params.stato) {
          case 0:
            dicitura = "elimin"
            break;
          case 1:
            dicitura = "abilit"
            break;
          case 2:
            dicitura = "disabilit"
            break;
        
          default:
            dicitura = "elimin"
            break;
        }
  
        this.axios
          .delete(`${this.$store.state.endpoint}/vettori?${new URLSearchParams(params).toString()}`)
          .then(() => {
            this.$store.state.snackbar = true
            this.$store.state.snackbar_text = `Vettore ${dicitura}ato con successo!`
            this.$store.state.snackbar_color = "success"
  
            this.$store.dispatch("getClienti")
            this.$store.dispatch("getVettori")
            this.closeDialog()
          })
          .catch((e) => {
            this.$store.state.snackbar_text = `Non è stato possibile ${dicitura}are il vettore`

            if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
              this.$store.state.snackbar_text += ": " + e.response.data.message
            }
            else {
              this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
            }
            
            this.$store.state.snackbar = true
            this.$store.state.snackbar_color = "error"
            console.log("Errore vettore", e)
          })
          .finally(() => {
            this.vettore_dialog_delete_loading = false
          });
      },
      getFormattedClienti(clienti) {
        if (this.$store.state.clienti && this.$store.state.clienti.length != 0) {
          return this.$store.state.clienti.filter(el => clienti.includes(el["id_cliente"])).map(el => el["ragione_sociale"]).join(", ")
        }

        return ""
      },
      getClienteFromId(id){
        if (this.$store.state.clienti && this.$store.state.clienti.length != 0) {
          return this.$store.state.clienti.filter(el => el["id_cliente"] == id)[0]
        }

        return null
      }
    }
  }
  </script>