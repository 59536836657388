<template>
  <v-card>
    <v-card-title>{{ value.nuova_data_chiusura ? "Crea Data Chiusura" : "Modifica Data Chiusura" }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="menu_data_inizio"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.data_inizio"
                  label="Data Inizio"
                  append-icon="mdi-calendar"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="obbligatorio_roule"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="value.data_inizio"
                @input="menu_data_inizio = false;"
                locale="it-IT"
                color="secondary"
                header-color="primary"
                first-day-of-week="1"
                show-adjacent-months
                :min="curDate"
                :max="value.data_fine"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="menu_data_fine"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.data_fine"
                  label="Data Fine"
                  append-icon="mdi-calendar"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="obbligatorio_roule"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="value.data_fine"
                @input="menu_data_fine = false;"
                locale="it-IT"
                color="secondary"
                header-color="primary"
                first-day-of-week="1"
                show-adjacent-months
                :min="value.data_inizio ? value.data_inizio : curDate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu_ora_inizio"
              v-model="menu_ora_inizio"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="value.ora_inizio"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.ora_inizio"
                  label="Ora Inizio"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="obbligatorio_roule"
                  clearable
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu_ora_inizio"
                v-model="value.ora_inizio"
                full-width
                @click:minute="$refs.menu_ora_inizio.save(value.ora_inizio)"
                format="24hr"
                :min="$store.getters.getFirstTime"
                :max="value.ora_fine && value.data_inizio == value.data_fine ? value.ora_fine : $store.getters.getLastTime"
                :allowed-minutes="$store.state.steps_time_picker"
                scrollable
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu_ora_fine"
              v-model="menu_ora_fine"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="value.ora_fine"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.ora_fine"
                  label="Ora Fine"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="obbligatorio_roule"
                  clearable
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu_ora_fine"
                v-model="value.ora_fine"
                full-width
                @click:minute="$refs.menu_ora_fine.save(value.ora_fine)"
                format="24hr"
                :min="value.ora_inizio && value.data_inizio == value.data_fine ? value.ora_inizio : $store.getters.getFirstTime"
                :max="$store.getters.getLastTime"
                :allowed-minutes="$store.state.steps_time_picker"
                scrollable
              ></v-time-picker>
            </v-menu>

            {{ value.data_fine }}
          </v-col>
          <v-col cols="12">
            <v-combobox 
              label="Tipi scarico"
              multiple
              v-model="value.tipi_scarico"
              :items="$store.getters.getTipiScaricoAttivi"
              item-value="id_tipo_scarico"
              item-text="descrizione"
              :maxlength="60"
              counter="60"
              :rules="obbligatorio_roule"
              chips
            > 
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :input-value="selected"
                >
                  {{ item.descrizione }}
                  <v-icon
                    small
                    @click="parent.selectItem(item)"
                  >
                    mdi-close
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="secondary"
        text
        @click="closeDialog"
      >
        Annulla
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="saveDataChiusura"
        :disabled="loading || !valid"
        :loading="loading"
      >
        Salva
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    loading: false,
    menu_data_inizio: false,
    menu_data_fine: false,
    menu_ora_inizio: false,
    menu_ora_fine: false,
    curDate: null,
    obbligatorio_roule: [v => !!v || 'Campo obbligatorio!'],
    zero_obbligatorio_roule: [v => !!v || v == 0 || 'Campo obbligatorio'],
    solo_numeri_roule: [
      v => !v || !isNaN(v) || 'Inserire un numero valido!',
      v => !v || !v.includes('.') || 'Il campo consente solo numeri!',
      v => !v || !v.includes(',') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('-') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('+') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('e') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('E') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('/') || 'Il campo consente solo numeri!'
    ],
    edit_rules_email: [v => !v || /.+@.+\..+/.test(v) || 'E-mail non valida!'],
  }),
  props: ['value'],
  created() {
    var d = new Date()
    this.curDate = `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`

    //  Per evitare di avere una data vecchia al cambio d'ora
    setInterval(() => {
      var d = new Date()
      this.curDate = `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
    }, 600000) // 10 min
    
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog")
      this.$refs.form.reset()
    },
    saveDataChiusura() {
      this.$refs.form.validate()
      this.$nextTick(() => {
        if(!this.valid) {
          return
        }

        if (!this.checkDataOra()) {
          //  Devo fare così altrimenti non mi aggionrava i campi
          this.menu_ora_fine = true
          this.menu_data_fine = true
          this.$nextTick(() => {
            this.value.data_fine = ""
            this.value.ora_fine = ""
            this.menu_ora_fine = false
            this.menu_data_fine = false
          })
          this.value.data_fine = ""
          this.value.ora_fine = ""

          this.$refs.form.validate()

        }
        else {
          this.value.data_ora_inizio = `${this.value.data_inizio} ${this.value.ora_inizio}`
          this.value.data_ora_fine = `${this.value.data_fine} ${this.value.ora_fine}`
  
          if (this.value.nuova_data_chiusura) {
            this.creaDataChiusura()
          }
          else {
            this.modificaDataChiusura()
          }
        }
      })
    },
    creaDataChiusura() {
      this.loading = true

      this.axios
        .post(`${this.$store.state.endpoint}/date-chiusura`, {
          data_ora_inizio: this.value.data_ora_inizio,
          data_ora_fine: this.value.data_ora_fine,
          tipi_scarico: this.value.tipi_scarico.map(el => el["id_tipo_scarico"])
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Data di chiusura creato con successo!"
          this.$store.state.snackbar_color = "success"

          this.$emit("update-date-chiusura")
          this.closeDialog()
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile creare la data di chiusura"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore data chiusura", e)
        })
        .finally(() => {
          this.loading = false
        });
    },
    modificaDataChiusura() {
      this.loading = true

      this.axios
        .put(`${this.$store.state.endpoint}/date-chiusura`, {
          id_chiusura: this.value.id_chiusura,
          data_ora_inizio: this.value.data_ora_inizio,
          data_ora_fine: this.value.data_ora_fine,
          tipi_scarico: this.value.tipi_scarico.map(el => el["id_tipo_scarico"])
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Data di chiusura modificato con successo!"
          this.$store.state.snackbar_color = "success"

          this.$emit("update-date-chiusura")
          this.closeDialog()
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile modificare la data di chiusura"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore data chiusura", e)
        })
        .finally(() => {
          this.loading = false
        });
    },
    checkDataOra() {
      return new Date(`${this.value.data_inizio} ${this.value.ora_inizio}`) < new Date(`${this.value.data_fine} ${this.value.ora_fine}`)
    }
  }
}
</script>

<style>

</style>