<template>
  <v-card>
    <v-card-title>{{ value.nuovo_vettore ? "Crea Vettore" : "Modifica Vettore" }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12">
            <v-text-field 
              label="Ragione Sociale"
              v-model="value.ragione_sociale"
              :rules="obbligatorio_roule"
              :maxlength="40"
              counter="40"
            />
          </v-col>
          <v-col cols="12">
            <v-combobox 
              label="Clienti"
              multiple
              v-model="value.clienti"
              :items="$store.getters.getClientiAttivi"
              item-value="id_cliente"
              item-text="ragione_sociale"
              :maxlength="60"
              counter="60"
              :rules="obbligatorio_roule"
              chips
            > 
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :input-value="selected"
                >
                  {{ item.ragione_sociale }}
                  <v-icon
                    small
                    @click="parent.selectItem(item)"
                  >
                    mdi-close
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="secondary"
        text
        @click="closeDialog"
      >
        Annulla
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="saveVettore"
        :disabled="loading || !valid"
        :loading="loading"
      >
        Salva
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    loading: false,
    obbligatorio_roule: [v => !!v || 'Campo obbligatorio!'],
    zero_obbligatorio_roule: [v => !!v || v == 0 || 'Campo obbligatorio'],
    solo_numeri_roule: [
      v => !v || !isNaN(v) || 'Inserire un numero valido!',
      v => !v || !v.includes('.') || 'Il campo consente solo numeri!',
      v => !v || !v.includes(',') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('-') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('+') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('e') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('E') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('/') || 'Il campo consente solo numeri!'
    ],
    edit_rules_email: [v => !v || /.+@.+\..+/.test(v) || 'E-mail non valida!'],
  }),
  props: ['value'],
  methods: {
    closeDialog() {
      this.$emit("close-dialog")
      this.$refs.form.reset()
    },
    saveVettore() {
      this.$refs.form.validate()
      this.$nextTick(() => {
        if(!this.valid) {
          return
        }

        if (this.value.nuovo_vettore) {
          this.creaVettore()
        }
        else {
          this.modificaVettore()
        }
      })
    },
    creaVettore() {
      this.loading = true

      this.axios
        .post(`${this.$store.state.endpoint}/vettori`, {
          ragione_sociale: this.value.ragione_sociale,
          clienti: this.value.clienti.map(el => el["id_cliente"])
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Vettore creato con successo!"
          this.$store.state.snackbar_color = "success"

          this.$emit("update-vettori")
          this.closeDialog()
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile creare il vettore"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore vettore", e)
        })
        .finally(() => {
          this.loading = false
        });
    },
    modificaVettore() {
      this.loading = true

      this.axios
        .put(`${this.$store.state.endpoint}/vettori`, {
          id_vettore: this.value.id_cliente,
          ragione_sociale: this.value.ragione_sociale,
          clienti: this.value.clienti.map(el => el["id_cliente"])
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Vettore modificato con successo!"
          this.$store.state.snackbar_color = "success"

          this.$emit("update-vettori")
          this.closeDialog()
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile modificare il vettore"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore vettore", e)
        })
        .finally(() => {
          this.loading = false
        });
    }
  }
}
</script>

<style>

</style>