import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#77af75',
        secondary: '#424242', //  Uso il colore come sfondo neutro e per i bottoni "Annulla" o "Chiudi"
        accent: '#424242' 
        //  error: '#da0000'
      }
    }
  }
});
