<template>
    <v-container class="fill-height pa-0" fluid>
      <v-data-table
        ref="data_table"
        :headers="headers"
        :items="$store.state.clienti"
        style="width:100%"
        height="calc(100vh - 200px)"
        :items-per-page="15"
        :search="search"
        no-results-text="Nessun risultato"
        fixed-header
        :footer-props="{
          'items-per-page-options': [15, 25, 50, -1],
          'items-per-page-text': 'Elementi per pagina',
          'items-per-page-all-text': 'Tutti',
        }"
        class="mt-3"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              label="Cerca"
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
            ></v-text-field>
  
            <v-spacer></v-spacer>
  
            <v-btn
              v-blur
              fab
              small
              color="primary"
              @click="openAggiungiCliente"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.id_comparto`]="{ item }">
          {{ getCodiceComparto(item.id_comparto) }}
        </template>

        <template v-slot:[`item.fascia_1`]="{ item }">
          {{ getFormattedFasciaOraria(item.inizio_fascia_1, item.fine_fascia_1) }}
        </template>

        <template v-slot:[`item.fascia_2`]="{ item }">
          {{ getFormattedFasciaOraria(item.inizio_fascia_2, item.fine_fascia_2) }}
        </template>

        <template v-slot:[`item.vettori`]="{ item }">
          <v-tooltip bottom v-if="item.vettori.length > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="rgba(0, 0, 0, 0.12)" 
              >                  
                {{item.vettori.length}}
              </v-chip>
            </template>
            <span>{{ 
              item.vettori.map(el => {
                var vettore = getVettoreFromId(el);
                return vettore && vettore["ragione_sociale"] ? vettore["ragione_sociale"] : "";
              }).join(", ")
            }}
            </span>
          </v-tooltip>

          <!-- Non mostro il tootlip se non ci sono vettori associati -->
          <v-chip 
            v-else
          >
            {{item.vettori.length}}
          </v-chip>
        </template>
  
        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="item.stato == 1">
            <v-icon small class="mr-2" @click="openModificaCliente(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="openModificaStatoCliente(item, 2)"> 
              mdi-cancel 
            </v-icon>
            <v-icon small @click="openModificaStatoCliente(item, 0)"> 
              mdi-delete 
            </v-icon>
          </div>
          <div v-else>
            <v-btn
              color="grey lighten-2"
              @click="openModificaStatoCliente(item, 1)"
              elevation="0"
            >
              Attiva
            </v-btn>
          </div>
        </template>
      </v-data-table>
  
      <v-dialog v-model="cliente_dialog" max-width="800px" persistent>
        <AggiungiModificaCliente v-model="cliente_edit" ref="cliente_dialog" @close-dialog="closeDialog" @update-clienti="updateClientiVettori()"/>
      </v-dialog>
  
      <v-dialog v-model="cliente_dialog_delete" max-width="500px" persistent>
        <v-card style="text-align: center;">
          <v-card-title class="text-h5" style="justify-content: center;">Attenzione</v-card-title>
          <v-card-text class="pb-0">
            <span class="black--text text-subtitle-1">
              Sei sicuro di voler {{ cliente_delete["stato"] == 2 ? "disabilitare" : cliente_delete["stato"] == 1 ? "abilitare" : "cancellare" }} il cliente <b>{{ cliente_delete["ragione_sociale"] }}</b>?       
            </span>
            <br>
            <span v-if="cliente_delete['stato'] == 0" class="black--text text-subtitle-1">
              Verranno inoltre cancellate le relative utenze.
            </span>
            <span v-if="cliente_delete['stato'] == 2" class="black--text text-subtitle-1">
              Tutte le utenze del cliente verranno disabilitate.
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              color="secondary" 
              text 
              @click="closeDialog"
            >
              Annulla
            </v-btn>
            <v-btn 
              color="primary" 
              text 
              @click="modificaStatoCliente"
              :loading="cliente_dialog_delete_loading"
              :disabled="cliente_dialog_delete_loading"
              >
              Conferma
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import AggiungiModificaCliente from '../components/AggiungiModificaCliente.vue'
  
  export default {
    data: () => ({
      search: "",
      loading: false,
      cliente_dialog: false,
      cliente_dialog_delete: false,
      cliente_dialog_delete_loading: false,
      cliente_edit: {},
      cliente_delete: {},
      cliente_edit_default: {
        id_cliente: null,
        id_comparto: "",
        ragione_sociale: "",
        inizio_fascia_1: "",
        fine_fascia_1: "",
        inizio_fascia_2: "",
        fine_fascia_2: "",
        max_scarichi: "",
        tipo: 1,
        vettori: [],
        data_ultima_modifica: "",
        user_ultima_modifica: "",
        stato: ""
      },
    }),
    computed: {
      headers(){
        return [
          {
            text: "Codice",
            value: "cd_AS400",
          },
          {
            text: "Ragione Sociale",
            value: "ragione_sociale",
          },
          {
            text: "Comparto",
            value: "id_comparto",
            // filter: (value, search) => {
            //   console.log(this.getCodiceComparto(value), search, this.getCodiceComparto(value).toLowerCase().includes(search.toLowerCase()))
            //   return this.getCodiceComparto(value).toLowerCase().includes(search.toLowerCase())
            // }
          },
          {
            text: "Fascia 1",
            value: "fascia_1",
          },
          {
            text: "Fascia 2",
            value: "fascia_2",
          },
          {
            text: "Max scarichi giorno",
            value: "max_scarichi",
          },
          {
            text: "Vettori",
            value: "vettori",
          },
          { text: "Azioni", value: "actions", sortable: false, align: "center"},
        ]
      } 
    },
    components: {
      AggiungiModificaCliente
    },
    mounted() {
      this.loading = true;
      var promise_vettori = null
      var promise_comparti = null

      if (!this.$store.state.comparti || this.$store.state.comparti.length == 0) {
        promise_comparti = this.$store.dispatch("getComparti")
      }
      if (!this.$store.state.vettori || this.$store.state.vettori.length == 0) {
        promise_vettori = this.$store.dispatch("getVettori")
      }

      Promise.all([promise_vettori, promise_comparti]).then(() => {
        if (!this.$store.state.clienti || this.$store.state.clienti.length == 0) {
          this.$store.dispatch("getClienti").finally(() => {
            this.loading = false
          })
        }
        else {
          this.$refs.data_table.$forceUpdate();
          this.loading = false
        }
      })
    },
    methods: {
      updateClientiVettori() {
        this.$store.dispatch('getVettori')
        this.$store.dispatch("getClienti")
      },
      closeDialog() {
        this.cliente_dialog = false
        this.cliente_dialog_delete = false
        this.cliente_edit = Object.assign({}, this.cliente_edit_default)
        this.cliente_delete = Object.assign({}, this.cliente_edit_default)
      },
      openAggiungiCliente() {
        this.cliente_edit = Object.assign({}, this.cliente_edit_default)
        this.cliente_edit["nuovo_cliente"] = true
        this.cliente_dialog = true
      },
      openModificaCliente(item) {
        for (var prop in item) {
          if (Object.prototype.hasOwnProperty.call(item, prop) && item[prop]) {
            //  A volte questo parametro viene passato come testo se c'è una singola occorrenza
            if(prop == "vettori") {
              if (!Array.isArray(item[prop])){
                item[prop] = [item[prop]]
              }
            }
            else if(prop != "id_comparto") {
              item[prop] = `${item[prop]}`
            }
          }
        }

        this.cliente_edit = Object.assign({}, item)
        this.cliente_edit["nuovo_cliente"] = false

        //  Trasformo gli id in oggetti per precompilare la combobox
        if(this.cliente_edit["vettori"] && Array.isArray(this.cliente_edit["vettori"])) {
          this.cliente_edit["vettori"] = this.cliente_edit["vettori"].map((el) => this.getVettoreFromId(el))
        }

        this.cliente_dialog = true
      },
      openModificaStatoCliente(item, stato = 0) {
        this.cliente_delete = Object.assign({}, item)
        this.cliente_delete["stato"] = stato
        this.cliente_dialog_delete = true
      },
      modificaStatoCliente() {
        this.cliente_dialog_delete_loading = true
  
        var params = {
          id_cliente: this.cliente_delete.id_cliente,
          stato: this.cliente_delete.stato
        }

        var dicitura = ""
        switch (params.stato) {
          case 0:
            dicitura = "elimin"
            break;
          case 1:
            dicitura = "abilit"
            break;
          case 2:
            dicitura = "disabilit"
            break;
        
          default:
            dicitura = "elimin"
            break;
        }
  
        this.axios
          .delete(`${this.$store.state.endpoint}/clienti?${new URLSearchParams(params).toString()}`)
          .then(() => {
            
            this.$store.state.snackbar = true
            
            this.$store.state.snackbar_text = `Cliente ${dicitura}ato con successo!`
            this.$store.state.snackbar_color = "success"
  
            this.$store.dispatch("getClienti")
            this.$store.dispatch("getVettori")
            this.closeDialog()
          })
          .catch((e) => {
            this.$store.state.snackbar_text = `Non è stato possibile ${dicitura}are il cliente`

            if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
              this.$store.state.snackbar_text += ": " + e.response.data.message
            }
            else {
              this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
            }

            this.$store.state.snackbar = true
            this.$store.state.snackbar_color = "error"
            console.log("Errore cliente", e)
          })
          .finally(() => {
            this.cliente_dialog_delete_loading = false
          });
      },
      getFormattedFasciaOraria(inizio, fine) {
        if (inizio && fine) {
          return `${inizio} - ${fine}`
        }
      },
      getCodiceComparto(id) {
        if (this.$store.state.comparti && this.$store.state.comparti.length != 0) {
          var comparto = this.$store.state.comparti.filter(el => el["id_comparto"] == id)[0]

          if (comparto) {
            return comparto["codice"]
          }
        }

        return ""
      },
      getVettoreFromId(id){
        if (this.$store.state.vettori && this.$store.state.vettori.length != 0) {
          return this.$store.state.vettori.filter(el => el["id_cliente"] == id)[0]
        }

        return null
      }
    }
  }
  </script>
  
  <style>
  
  </style>