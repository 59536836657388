<template>
  <v-card>
    <v-card-title>{{ value.nuova_utenza ? "Crea Utenza" : "Modifica Utenza" }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field 
              label="Username"
              v-model="value.username"
              :rules="obbligatorio_roule"
              :maxlength="40"
              counter="40"
              :disabled="!value.nuova_utenza"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field 
              label="Email"
              v-model="value.email"
              :rules="[...obbligatorio_roule, ...edit_rules_email]"
              :maxlength="60"
              counter="60"
              :disabled="!value.nuova_utenza"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field 
              label="Nome"
              v-model="value.nome"
              :rules="obbligatorio_roule"
              :maxlength="60"
              counter="60"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field 
              label="Telefono"
              v-model="value.telefono"
              :rules="[...obbligatorio_roule, ...solo_numeri_roule]"
              :maxlength="15"
              counter="15"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              label="Permessi"
              v-model="value.tipo"
              :items="$store.state.permessi_array"
              item-value="id"
              item-text="text"
              :rules="obbligatorio_roule"
              @change="value.id_cliente = null"
              :disabled="$store.state.permessi < 8"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-if="[1,2,3,4].includes(value.tipo)"
              :label="value.tipo == 1 || value.tipo == 3 ? 'Vettore' : 'Cliente'"
              v-model="value.id_cliente"
              :items="value.tipo == 1 || value.tipo == 3 ? $store.getters.getVettoriAttivi : $store.getters.getClientiAttivi"
              item-value="id_cliente"
              item-text="ragione_sociale"
              :rules="zero_obbligatorio_roule"
              :disabled="$store.state.permessi < 8"
            />
          </v-col>
          <v-col cols="12" v-if="value.nuova_utenza">
            <span>Creando l'utenza, verrà automaticamente generata ed inviata all'indirizzo email inserito una password provvisoria. Al primo accesso verrà richiesto di inserirne una definitiva.</span>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="!value.nuova_utenza"
        color="warning"
        text
        @click="resetpsw_dialog = true">
        Reset Password
      </v-btn>
      <v-spacer/>
      <v-btn
        color="secondary"
        text
        @click="closeDialog"
      >
        Annulla
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="saveUtenza"
        :disabled="loading || !valid"
        :loading="loading"
      >
        Salva
      </v-btn>
    </v-card-actions>

    <!-- Dialog Reset password -->
    <v-dialog v-model="resetpsw_dialog" max-width="500px" persistent>
      <v-card style="text-align: center;">
        <v-card-title class="text-h5" style="justify-content: center;">Attenzione</v-card-title>
        <v-card-text class="pb-0">
          <span class="black--text text-subtitle-1">Sei sicuro di voler ripristinare la password dell'utenza <b>{{ value["username"]}}</b>? Verrà generata una nuova password ed inviata tramite mail all'indirizzo <b>{{ value["email"]}}</b></span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="secondary" 
            text 
            @click="resetpsw_dialog = false"
          >
            Annulla
          </v-btn>
          <v-btn 
            color="primary" 
            text 
            @click="resetPsw"
            :loading="loading_reset"
            :disabled="loading_reset"
            >
            Conferma
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    loading: false,
    obbligatorio_roule: [v => !!v || 'Campo obbligatorio!'],
    zero_obbligatorio_roule: [v => !!v || v == 0 || 'Campo obbligatorio'],
    solo_numeri_roule: [
      v => !v || !isNaN(v) || 'Inserire un numero valido!',
      v => !v || !v.includes('.') || 'Il campo consente solo numeri!',
      v => !v || !v.includes(',') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('-') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('+') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('e') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('E') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('/') || 'Il campo consente solo numeri!'
    ],
    edit_rules_email: [v => !v || /.+@.+\..+/.test(v) || 'E-mail non valida!'],
    resetpsw_dialog: false,
    loading_reset: false,
  }),
  props: ['value'],
  methods: {
    closeDialog() {
      this.$emit("close-dialog")
      this.$refs.form.reset()
    },
    saveUtenza() {
      this.$refs.form.validate()
      this.$nextTick(() => {
        if(!this.valid) {
          return
        }

        if (this.value.nuova_utenza) {
          this.creaUtenza()
        }
        else {
          this.modificaUtenza()
        }
      })
    },
    creaUtenza() {
      this.loading = true

      this.axios
        .post(`${this.$store.state.endpoint}/utenze`, {
          username: this.value.username,
          email: this.value.email,
          nome: this.value.nome,
          telefono: this.value.telefono,
          tipo: this.value.tipo,
          id_cliente: this.value.id_cliente,
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Utenza creata con successo!"
          this.$store.state.snackbar_color = "success"

          this.$emit("update-utenze")
          this.closeDialog()
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile creare l'utenza"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore utenza", e)
        })
        .finally(() => {
          this.loading = false
        });
    },
    modificaUtenza() {
      this.loading = true

      this.axios
        .put(`${this.$store.state.endpoint}/utenze`, {
          id_utenza: this.value.id_utenza,
          // email: this.value.email,
          nome: this.value.nome,
          telefono: this.value.telefono,
          tipo: this.value.tipo,
          id_cliente: this.value.id_cliente,
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Utenza modificata con successo!"
          this.$store.state.snackbar_color = "success"

          this.$emit("update-utenze")
          this.closeDialog()
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile modificare l'utenza"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore utenza", e)
        })
        .finally(() => {
          this.loading = false
        });
    },
    resetPsw() {
      this.loading_reset = true
      this.axios
        .post(`${this.$store.state.endpoint}/reset-password`, {
          id_utenza: this.value.id_utenza
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Password ripristinata con successo!"
          this.$store.state.snackbar_color = "success"

          this.resetpsw_dialog = false
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile ripristinare la password"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore utenza", e)
        })
        .finally(() => {
          this.loading_reset = false
        });
    }
  }
}
</script>

<style>

</style>