<template>
  <v-card>
    <v-card-title>{{ value.nuovo_stato ? "Crea Stato" : "Modifica Stato" }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12">
            <v-text-field 
              label="Descrizione"
              v-model="value.descrizione"
              :rules="obbligatorio_roule"
              :maxlength="40"
              counter="40"
            />
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="value.opaco"
              label="Opacizza"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="secondary"
        text
        @click="closeDialog"
      >
        Annulla
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="saveStato"
        :disabled="loading || !valid"
        :loading="loading"
      >
        Salva
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    loading: false,
    obbligatorio_roule: [v => !!v || 'Campo obbligatorio!'],
    zero_obbligatorio_roule: [v => !!v || v == 0 || 'Campo obbligatorio'],
    solo_numeri_roule: [
      v => !v || !isNaN(v) || 'Inserire un numero valido!',
      v => !v || !v.includes('.') || 'Il campo consente solo numeri!',
      v => !v || !v.includes(',') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('-') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('+') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('e') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('E') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('/') || 'Il campo consente solo numeri!'
    ],
    edit_rules_email: [v => !v || /.+@.+\..+/.test(v) || 'E-mail non valida!'],
  }),
  props: ['value'],
  methods: {
    closeDialog() {
      this.$emit("close-dialog")
      this.$refs.form.reset()
    },
    saveStato() {
      this.$refs.form.validate()
      this.$nextTick(() => {
        if(!this.valid) {
          return
        }

        if (this.value.nuovo_stato) {
          this.creaStato()
        }
        else {
          this.modificaStato()
        }
      })
    },
    creaStato() {
      this.loading = true

      this.axios
        .post(`${this.$store.state.endpoint}/stati-prenotazione`, {
          descrizione: this.value.descrizione,
          opaco: this.value.opaco,
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Stato creato con successo!"
          this.$store.state.snackbar_color = "success"

          this.$emit("update-stati")
          this.closeDialog()
        })
        .catch((e) => {          
          this.$store.state.snackbar_text = "Non è stato possibile creare lo stato"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore stato", e)
        })
        .finally(() => {
          this.loading = false
        });
    },
    modificaStato() {
      this.loading = true

      this.axios
        .put(`${this.$store.state.endpoint}/stati-prenotazione`, {
          id_stato: this.value.id_stato,
          descrizione: this.value.descrizione,
          opaco: this.value.opaco,
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Stato modificato con successo!"
          this.$store.state.snackbar_color = "success"

          this.$emit("update-stati")
          this.closeDialog()
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile modificare lo stato"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore stato", e)
        })
        .finally(() => {
          this.loading = false
        });
    }
  }
}
</script>

<style>

</style>