<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12">
          <p class="pt-4 mb-2 fake-card-title" style="">Logo</p>
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-hover v-slot="{ hover }">
            <div style="position: relative; width: fit-content; border-radius: 10px;" class="pa-3">
              <v-img 
                :src="edit_ambiente.logo" 
                style="height: 80px"
                contain
              > 
              </v-img>
              <v-overlay
                :absolute="true"
                :value="hover"
              >
                <v-btn
                  v-if="hover"
                  v-blur
                  icon
                  @click="logo_dialog = true"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-overlay>
            </div>
          </v-hover>
        </v-col>  
        <v-col cols="12">
          <p class="pt-4 mb-2 fake-card-title" style="">Informazioni sull'azienda</p>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field 
            label="Ragione sociale"
            v-model="edit_ambiente.ragione_sociale"
            :rules="obbligatorio_roule"
            :maxlength="60"
            counter="60"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field 
            label="Telefono"
            v-model="edit_ambiente.telefono"
            :rules="[...obbligatorio_roule, ...solo_numeri_roule]"
            :maxlength="15"
            counter="15"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field 
            label="Indirizzo"
            v-model="edit_ambiente.indirizzo"
            :rules="obbligatorio_roule"
            :maxlength="255"
            counter="255"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field 
            label="Email azienda"
            v-model="edit_ambiente.email_azienda"
            :rules="[...edit_rules_email, ...obbligatorio_roule]"
            :maxlength="60"
            counter="60"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field 
            label="Email mittenza"
            v-model="edit_ambiente.email_mittente"
            :rules="[...edit_rules_email, ...obbligatorio_roule]"
            :maxlength="60"
            counter="60"
          />
        </v-col>
        <v-col cols="12">
          <p class="pt-4 mb-2 fake-card-title" style="">Colori portale</p>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-center">
          <v-color-picker
            v-model="edit_ambiente.colore_primario"
            hide-mode-switch
          ></v-color-picker>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-center">
          <v-color-picker
            v-model="edit_ambiente.colore_secondario"
            hide-mode-switch
          ></v-color-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="pt-4 mb-2 fake-card-title" style="">Fasce orarie di apertura</p>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            ref="menu_inizio_fascia_1"
            v-model="menu_inizio_fascia_1"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="edit_ambiente.inizio_fascia_1"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="edit_ambiente.inizio_fascia_1"
                label="Inizio fascia 1"
                append-icon="mdi-clock-time-four-outline"
                readonly
                color="primary"
                v-bind="attrs"
                v-on="on"
                :rules="obbligatorio_roule"
                clearable
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu_inizio_fascia_1"
              v-model="edit_ambiente.inizio_fascia_1"
              full-width
              @click:minute="$refs.menu_inizio_fascia_1.save(edit_ambiente.inizio_fascia_1)"
              format="24hr"
              :min="$store.getters.getFirstTime"
              :max="edit_ambiente.fine_fascia_1 ? edit_ambiente.fine_fascia_1 : $store.getters.getLastTime"
              :allowed-minutes="$store.state.steps_time_picker"
              scrollable
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            ref="menu_fine_fascia_1"
            v-model="menu_fine_fascia_1"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="edit_ambiente.fine_fascia_1"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="edit_ambiente.fine_fascia_1"
                label="Fine fascia 1"
                append-icon="mdi-clock-time-four-outline"
                readonly
                color="primary"
                v-bind="attrs"
                v-on="on"
                :rules="obbligatorio_roule"
                clearable
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu_fine_fascia_1"
              v-model="edit_ambiente.fine_fascia_1"
              full-width
              @click:minute="$refs.menu_fine_fascia_1.save(edit_ambiente.fine_fascia_1)"
              format="24hr"
              :min="edit_ambiente.inizio_fascia_1 ? edit_ambiente.inizio_fascia_1 : $store.getters.getFirstTime"
              :max="$store.getters.getLastTime"
              :allowed-minutes="$store.state.steps_time_picker"
              scrollable
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            ref="menu_inizio_fascia_2"
            v-model="menu_inizio_fascia_2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="edit_ambiente.inizio_fascia_2"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="edit_ambiente.inizio_fascia_2"
                label="Inizio fascia 2"
                append-icon="mdi-clock-time-four-outline"
                readonly
                color="primary"
                v-bind="attrs"
                v-on="on"
                :rules="edit_ambiente.fine_fascia_2 ? obbligatorio_roule : []"
                clearable
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu_inizio_fascia_2"
              v-model="edit_ambiente.inizio_fascia_2"
              full-width
              @click:minute="$refs.menu_inizio_fascia_2.save(edit_ambiente.inizio_fascia_2)"
              format="24hr"
              :min="$store.getters.getFirstTime"
              :max="edit_ambiente.fine_fascia_2 ? edit_ambiente.fine_fascia_2 : $store.getters.getLastTime"
              :allowed-minutes="$store.state.steps_time_picker"
              scrollable
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            ref="menu_fine_fascia_2"
            v-model="menu_fine_fascia_2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="edit_ambiente.fine_fascia_2"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="edit_ambiente.fine_fascia_2"
                label="Fine fascia 2"
                append-icon="mdi-clock-time-four-outline"
                readonly
                color="primary"
                v-bind="attrs"
                v-on="on"
                :rules="edit_ambiente.inizio_fascia_2 ? obbligatorio_roule : []"
                clearable
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu_fine_fascia_2"
              v-model="edit_ambiente.fine_fascia_2"
              full-width
              @click:minute="$refs.menu_fine_fascia_2.save(edit_ambiente.fine_fascia_2)"
              format="24hr"
              :min="edit_ambiente.inizio_fascia_2 ? edit_ambiente.inizio_fascia_2 : $store.getters.getFirstTime"
              :max="$store.getters.getLastTime"
              :allowed-minutes="$store.state.steps_time_picker"
              scrollable
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            label="Frequenza fasce"
            v-model="edit_ambiente.ampiezza_fascia"
            :items="ampiezza_fascia"
            item-value="value"
            item-text="text"
            :rules="obbligatorio_roule"
            @change="value.id_cliente = null"
          />
        </v-col>
      </v-row>
      <v-row class="ma-3">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="saveAmbiente"
          :disabled="loading || !valid"
          :loading="loading"
        >
          Salva Modifiche
        </v-btn>
      </v-row>
    </v-form>

    <!-- Dialog modifica logo-->
    <v-dialog v-model="logo_dialog" max-width="500px">
      <v-card>
        <v-card-title>
          Modifica Logo
        </v-card-title>
        <v-card-text>
          <v-file-input
            v-model="edit_logo"
            accept="image/*"
            label="File input"
            @change="fileLoaded"
          ></v-file-input>
          <v-img v-if="edit_logo" :src="edit_logo_url" contain></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="secondary"
            text
            @click="closeDialog"
          >
            Annulla
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="editLogo"
            :disabled="!edit_logo"
          >
            Conferma
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    loading: false,
    edit_ambiente: {
      colore_primario: "#FF0000",
      colore_secondario: "#FF0000",
    },
    edit_logo: null,
    edit_logo_url: "",
    menu_inizio_fascia_1: false,
    menu_fine_fascia_1: false,
    menu_inizio_fascia_2: false,
    menu_fine_fascia_2: false,
    ampiezza_fascia: [
      {value: 0.5, text: "30 min"}, 
      {value: 1, text: "1 ora"}, 
      {value: 1.5, text: "1 ora 30 min"}, 
      {value: 2, text: "2 ore"}, 
    ],
    logo_dialog: false,
    obbligatorio_roule: [v => !!v || 'Campo obbligatorio!'],
    zero_obbligatorio_roule: [v => !!v || v == 0 || 'Campo obbligatorio'],
    solo_numeri_roule: [
      v => !v || !isNaN(v) || 'Inserire un numero valido!',
      v => !v || !v.includes('.') || 'Il campo consente solo numeri!',
      v => !v || !v.includes(',') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('-') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('+') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('e') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('E') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('/') || 'Il campo consente solo numeri!'
    ],
    edit_rules_email: [v => !v || /.+@.+\..+/.test(v) || 'E-mail non valida!'],

  }),
  mounted() {
    this.getAmbiente()
    this.edit_ambiente = Object.assign({}, this.$store.state.ambiente)
  },
  methods: {
    getAmbiente() {
      this.$store.state.loaded = false
      this.axios
        .get(`${this.$store.state.endpoint}/ambiente`)
        .then((response) => {
          this.edit_ambiente = response.data
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile scaricare i dati dell'ambiente"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log(e)
        }).finally(() => {
          this.$store.state.loaded = true
        });
    },
    editLogo() {
      this.edit_ambiente.file_logo = this.edit_logo
      this.edit_ambiente.logo = URL.createObjectURL(this.edit_ambiente.file_logo)

      this.logo_dialog = false
      setTimeout(() => {
        this.edit_logo = null
        this.edit_logo_url = ""
      }, 200)
    },
    saveAmbiente() {
      this.$refs.form.validate()
      this.$nextTick(() => {
        if (!this.valid) {
          return
        }

        this.loading = true;
        this.axios
          .put(`${this.$store.state.endpoint}/ambiente`, {
            ragione_sociale: this.edit_ambiente.ragione_sociale,
            indirizzo: this.edit_ambiente.indirizzo,
            telefono: this.edit_ambiente.telefono,
            colore_primario: this.edit_ambiente.colore_primario,
            colore_secondario: this.edit_ambiente.colore_secondario,
            inizio_fascia_1: this.edit_ambiente.inizio_fascia_1,
            fine_fascia_1: this.edit_ambiente.fine_fascia_1,
            inizio_fascia_2: this.edit_ambiente.inizio_fascia_2,
            fine_fascia_2: this.edit_ambiente.fine_fascia_2,
            ampiezza_fascia: this.edit_ambiente.ampiezza_fascia,
            email_azienda: this.edit_ambiente.email_azienda,
            email_mittente: this.edit_ambiente.email_mittente,
            file_logo: this.edit_ambiente.file_logo
          })
          .then(() => {
            if(this.edit_ambiente.file_logo) {
              location.reload()
            }
  
            this.$store.state.inizio_fascia_1 = this.edit_ambiente.inizio_fascia_1
            this.$store.state.fine_fascia_1 = this.edit_ambiente.fine_fascia_1
            this.$store.state.inizio_fascia_2 = this.edit_ambiente.inizio_fascia_2
            this.$store.state.fine_fascia_2 = this.edit_ambiente.fine_fascia_2
            this.$store.state.ampiezza_fascia = this.edit_ambiente.ampiezza_fascia
            
            this.$vuetify.theme.themes.light.primary = this.edit_ambiente.colore_primario;
            this.$vuetify.theme.themes.light.secondary = this.edit_ambiente.colore_secondario;

            this.$store.state.snackbar = true
            this.$store.state.snackbar_text = "Ambiente modificato con successo"
            this.$store.state.snackbar_color = "success"
          })
          .catch((e) => {
            this.$store.state.snackbar_text = "Non è stato possibile modificare l'ambiente"

            if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
              this.$store.state.snackbar_text += ": " + e.response.data.message
            }
            else {
              this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
            }

            this.$store.state.snackbar = true
            this.$store.state.snackbar_color = "error"
            console.log("Errore vettore", e)
          })
          .finally(() => {
            this.loading = false
          });
      })

    },
    fileLoaded() {
      this.edit_logo_url = URL.createObjectURL(this.edit_logo)
    },
    closeDialog() {
      this.logo_dialog = false
      setTimeout(() => {
        this.edit_logo = null
        this.edit_logo_url = ""
      }, 200)
    }
  },
}
</script>

<style scoped>
.fake-card-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
}

.edit-logo{
  display: flex;
  align-items: flex-end;
}
</style>