<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" class="py-0">
        <v-text-field
          v-if="prenotazione.rag_soc_cliente"
          label="Cliente"
          v-model="prenotazione.rag_soc_cliente"
          readonly
        />
        <v-autocomplete 
          v-else
          label="Cliente"
          v-model="prenotazione.id_cliente"
          :items="$store.state.clienti"
          item-value="id_cliente"
          item-text="ragione_sociale"
          append-icon=""
          readonly
        />
        <v-text-field 
          label="Numero bancali"
          v-model="prenotazione.bancali"
          type="number"
          readonly
        />
      </v-col>
      <v-col cols="12" sm="6" class="py-0">
        <v-text-field
          v-if="prenotazione.tipo_scarico"
          label="Tipo scarico"
          v-model="prenotazione.tipo_scarico"
          readonly
        />
        <v-autocomplete
          v-else
          label="Tipo scarico"
          v-model="prenotazione.id_tipo_scarico"
          :items="$store.state.tipi_scarico"
          item-value="id_tipo_scarico"
          item-text="descrizione"
          append-icon=""
          readonly
        />
        <v-text-field 
          label="Documento di riferimetno"
          v-model="prenotazione.riferimento_documento"
          readonly
        />
      </v-col>
      <v-col cols="12" sm="6" class="py-0">
        <v-text-field 
          label="Data"
          v-model="formattedData"
          readonly
        />
      </v-col>
      <v-col cols="12" sm="6" class="py-0">
        <v-text-field 
          v-model="formattedFascia"
          label="Fascia Oraria"
          readonly
        />
      </v-col>
      <v-col cols="12" sm="6" class="py-0" v-if="$store.state.permessi >= 8">
        <v-text-field
          v-if="prenotazione.category"
          label="Comparto"
          v-model="prenotazione.category"
          readonly
        />
        <v-select
          v-else
          label="Comparto"
          v-model="prenotazione.id_comparto"
          :items="$store.state.comparti"
          item-value="id_comparto"
          item-text="codice"
          append-icon=""
          readonly
        />
      </v-col>
      <v-col cols="12" class="py-0" :sm="$store.state.permessi >= 8 ? 6 : 12">
        <v-text-field 
          v-model="prenotazione.mittente"
          label="Mittente"
          readonly
        />
      </v-col>
      <v-col v-if="$store.state.permessi >= 8 && prenotazione.desc_stato" cols="12" class="py-0">
        <v-text-field 
          v-model="prenotazione.desc_stato"
          label="Stato"
          readonly
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <p class="pt-4 mb-2 fake-card-title" style="">Informazioni sul vettore</p>
        <v-combobox 
          label="Ragione sociale"
          v-model="prenotazione.rag_soc_vettore"
          :items="[prenotazione.rag_soc_vettore]"
          item-value="id_cliente"
          item-text="ragione_sociale"
          :maxlength="60"
          counter="60"
          append-icon=""
          readonly
        />
      </v-col>
      <v-col cols="12" sm="6" class="py-0">
        <v-combobox 
          label="Persona di riferimento"
          v-model="prenotazione.nome_riferimento_vettore"
          :items="[prenotazione.nome_riferimento_vettore]"
          item-text="nome"
          :maxlength="60"
          counter="60"
          append-icon=""
          readonly
        />
      </v-col>
      <v-col cols="12" sm="6" class="py-0">
        <v-text-field 
          label="Telefono"
          v-model="prenotazione.telefono_riferimento_vettore"
          :maxlength="15"
          counter="15"
          readonly
        />
      </v-col>
      <v-col class="py-0" cols="12">
        <v-text-field 
          label="Email"
          v-model="prenotazione.email_riferimento_vettore"
          :maxlength="60"
          counter="60"
          readonly
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea 
          label="Note generali"
          v-model="prenotazione.note"
          readonly
          rows="3"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
  }),
  props: ['prenotazione'],
  methods: {},
  computed: {
    formattedFascia() {
      var inizio, fine

      if (this.prenotazione.fascia) {
        inizio = this.prenotazione.fascia[0]
        fine = this.prenotazione.fascia[1]
      }
      else {
        inizio = this.prenotazione.ora_inizio
        fine = this.prenotazione.ora_fine
      }

      return `${inizio} - ${fine}`
    },
    formattedData() {
      var data = this.prenotazione.date_picker ? this.prenotazione.date_picker : this.prenotazione.data
      return new Date(data).toLocaleDateString()
    }
  }
};
</script>

<style scoped>
.fake-card-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
}
</style>
