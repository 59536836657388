<template>
    <v-container class="fill-height pa-0" fluid>
      <v-data-table
        :headers="headers"
        :items="$store.state.date_chiusura"
        style="width:100%"
        height="calc(100vh - 200px)"
        :items-per-page="15"
        :search="search"
        no-results-text="Nessun risultato"
        fixed-header
        :footer-props="{
          'items-per-page-options': [15, 25, 50, -1],
          'items-per-page-text': 'Elementi per pagina',
          'items-per-page-all-text': 'Tutti',
        }"
        class="mt-3"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              label="Cerca"
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
            ></v-text-field>
  
            <v-spacer></v-spacer>
  
            <v-btn
              v-blur
              fab
              small
              color="primary"
              @click="openAggiungiDataChiusura"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.data_ora_inizio`]="{ item }">
          {{ item.data_ora_inizio ? new Date(item.data_ora_inizio).toLocaleString() : "" }}
        </template>

        <template v-slot:[`item.data_ora_fine`]="{ item }">
          {{ item.data_ora_fine ? new Date(item.data_ora_fine).toLocaleString() : "" }}
        </template>

        <template v-slot:[`item.tipi_scarico`]="{ item }">
          <v-tooltip bottom v-if="item.tipi_scarico && item.tipi_scarico.length > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="rgba(0, 0, 0, 0.12)" 
              >                  
                {{item.tipi_scarico.length}}
              </v-chip>
            </template>
            <span>{{ 
              item.tipi_scarico.map(el => {
                var tipo_scarico = getTipoScaricoFromId(el);
                return tipo_scarico && tipo_scarico["descrizione"] ? tipo_scarico["descrizione"] : "";
              }).join(", ")
            }}
            </span>
          </v-tooltip>

          <!-- Non mostro il tootlip se non ci sono vettori associati -->
          <v-chip 
            v-else
          >
            {{item.tipi_scarico.length}}
          </v-chip>
        </template>
  
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="openModificaDataChiusura(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="openCancellaDataChiusuraStato(item, 0)"> 
            mdi-delete 
          </v-icon>
        </template>
      </v-data-table>
  
      <v-dialog v-model="data_chiusura_dialog" max-width="800px" persistent>
        <AggiungiModificaDateChiusura v-model="data_chiusura_edit" ref="data_chiusura_dialog" @close-dialog="closeDialog" @update-date-chiusura="$store.dispatch('getDateChiusura')"/>
      </v-dialog>
  
      <v-dialog v-model="data_chiusura_dialog_delete" max-width="500px" persistent>
        <v-card style="text-align: center;">
          <v-card-title class="text-h5" style="justify-content: center;">Attenzione</v-card-title>
          <v-card-text class="pb-0">
            <span class="black--text text-subtitle-1">
              Sei sicuro di voler cancellare la data di chiusura?
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              color="secondary" 
              text 
              @click="closeDialog"
            >
              Annulla
            </v-btn>
            <v-btn 
              color="primary" 
              text 
              @click="cancellaDataChiusuraStato"
              :loading="data_chiusura_dialog_delete_loading"
              :disabled="data_chiusura_dialog_delete_loading"
              >
              Conferma
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import AggiungiModificaDateChiusura from '../components/AggiungiModificaDateChiusura.vue'
  
  export default {
    data: () => ({
      search: "",
      loading: false,
      data_chiusura_dialog: false,
      data_chiusura_dialog_delete: false,
      data_chiusura_dialog_delete_loading: false,
      data_chiusura_edit: {},
      data_chiusura_delete: {},
      data_chiusura_edit_default: {
        id_data_chiusura: null,
        descrizione: "",
        opaco: false,
      },
      headers: [
        {
          text: "Inizio",
          value: "data_ora_inizio",
        },
        {
          text: "Fine",
          value: "data_ora_fine",
        },
        {
          text: "Tipi Scarico",
          value: "tipi_scarico",
        },
        
        { text: "Azioni", value: "actions", sortable: false, align: "center"},
      ],
    }),
    components: {
      AggiungiModificaDateChiusura
    },
    mounted() {
      if (!this.$store.state.tipi_scarico || this.$store.state.tipi_scarico.length == 0) {
        this.$store.dispatch("getTipiScarico")
      }

      if (!this.$store.state.date_chiusura || this.$store.state.date_chiusura.length == 0) {
        this.loading = true
        this.$store.dispatch("getDateChiusura").finally(() => {this.loading = false})
      }
    },
    methods: {
      closeDialog() {
        this.data_chiusura_dialog = false
        this.data_chiusura_dialog_delete = false
        this.data_chiusura_edit = Object.assign({}, this.data_chiusura_edit_default)
        this.data_chiusura_delete = Object.assign({}, this.data_chiusura_edit_default)
      },
      openAggiungiDataChiusura() {
        this.data_chiusura_edit = Object.assign({}, this.data_chiusura_edit_default)
        this.data_chiusura_edit["nuova_data_chiusura"] = true
        this.data_chiusura_dialog = true
      },
      openModificaDataChiusura(item) {
        this.data_chiusura_edit = Object.assign({}, item)
        this.data_chiusura_edit["nuova_data_chiusura"] = false

        this.data_chiusura_edit["data_inizio"] = item.data_ora_inizio.substr(0, 10)
        this.data_chiusura_edit["ora_inizio"] = item.data_ora_inizio.substr(11, item.data_ora_fine.length - 14)
        this.data_chiusura_edit["data_fine"] = item.data_ora_fine.substr(0, 10)
        this.data_chiusura_edit["ora_fine"] = item.data_ora_fine.substr(11, item.data_ora_fine.length - 14)

        //  Trasformo gli id in oggetti per precompilare la combobox
        if(this.data_chiusura_edit["tipi_scarico"] && Array.isArray(this.data_chiusura_edit["tipi_scarico"])) {
          this.data_chiusura_edit["tipi_scarico"] = this.data_chiusura_edit["tipi_scarico"].map((el) => this.getTipoScaricoFromId(el))
        }

        this.data_chiusura_dialog = true
      },
      openCancellaDataChiusuraStato(item) {        
        this.data_chiusura_delete = Object.assign({}, item)
        this.data_chiusura_dialog_delete = true
      },
      cancellaDataChiusuraStato() {
        this.data_chiusura_dialog_delete_loading = true
  
        var params = {
          id_chiusura: this.data_chiusura_delete.id_chiusura
        }

        this.axios
          .delete(`${this.$store.state.endpoint}/date-chiusura?${new URLSearchParams(params).toString()}`)
          .then(() => {
            this.$store.state.snackbar = true
            this.$store.state.snackbar_text = `Data chiusura eliminata con successo!`
            this.$store.state.snackbar_color = "success"
  
            this.$store.dispatch("getDateChiusura")
            this.closeDialog()
          })
          .catch((e) => {
            this.$store.state.snackbar_text = `Non è stato possibile eliminare la data di chiusura`

            if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
              this.$store.state.snackbar_text += ": " + e.response.data.message
            }
            else {
              this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
            }
            
            this.$store.state.snackbar = true
            this.$store.state.snackbar_color = "error"
            console.log("Errore data chiusura", e)
          })
          .finally(() => {
            this.data_chiusura_dialog_delete_loading = false
          });
      },
      getTipoScaricoFromId(id){
        if (this.$store.state.tipi_scarico && this.$store.state.tipi_scarico.length != 0) {
          return this.$store.state.tipi_scarico.filter(el => el["id_tipo_scarico"] == id)[0]
        }

        return null
      }
    }
  }
  </script>
  
  <style>
  
  </style>