<template>
  <v-card>
    <v-card-title>{{ value.nuovo_cliente ? "Crea Cliente" : "Modifica Cliente" }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field 
              label="Codice"
              v-model="value.cd_AS400"
              :rules="solo_numeri_roule"
              :disabled="!value.nuovo_cliente"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field 
              label="Ragione Sociale"
              v-model="value.ragione_sociale"
              :rules="obbligatorio_roule"
              :maxlength="60"
              counter="60"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              label="Comparto"
              v-model="value.id_comparto"
              :items="$store.getters.getCompartiAttivi"
              item-value="id_comparto"
              item-text="codice"
              :rules="zero_obbligatorio_roule"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field 
              label="Max scarichi giorno"
              v-model="value.max_scarichi"
              :rules="[...obbligatorio_roule, ...solo_numeri_roule]"
              :maxlength="3"
              counter="3"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu_inizio_fascia_1"
              v-model="menu_inizio_fascia_1"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="value.inizio_fascia_1"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.inizio_fascia_1"
                  label="Inizio fascia 1"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="obbligatorio_roule"
                  clearable
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu_inizio_fascia_1"
                v-model="value.inizio_fascia_1"
                full-width
                @click:minute="$refs.menu_inizio_fascia_1.save(value.inizio_fascia_1)"
                format="24hr"
                :min="$store.getters.getFirstTime"
                :max="value.fine_fascia_1 ? value.fine_fascia_1 : $store.getters.getLastTime"
                :allowed-minutes="$store.state.steps_time_picker"
                scrollable
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu_fine_fascia_1"
              v-model="menu_fine_fascia_1"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="value.fine_fascia_1"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.fine_fascia_1"
                  label="Fine fascia 1"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="obbligatorio_roule"
                  clearable
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu_fine_fascia_1"
                v-model="value.fine_fascia_1"
                full-width
                @click:minute="$refs.menu_fine_fascia_1.save(value.fine_fascia_1)"
                format="24hr"
                :min="value.inizio_fascia_1 ? value.inizio_fascia_1 : $store.getters.getFirstTime"
                :max="$store.getters.getLastTime"
                :allowed-minutes="$store.state.steps_time_picker"
                scrollable
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu_inizio_fascia_2"
              v-model="menu_inizio_fascia_2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="value.inizio_fascia_2"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.inizio_fascia_2"
                  label="Inizio fascia 2"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="value.fine_fascia_2 ? obbligatorio_roule : []"
                  clearable
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu_inizio_fascia_2"
                v-model="value.inizio_fascia_2"
                full-width
                @click:minute="$refs.menu_inizio_fascia_2.save(value.inizio_fascia_2)"
                format="24hr"
                :min="$store.getters.getFirstTime"
                :max="value.fine_fascia_2 ? value.fine_fascia_2 : $store.getters.getLastTime"
                :allowed-minutes="$store.state.steps_time_picker"
                scrollable
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu_fine_fascia_2"
              v-model="menu_fine_fascia_2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="value.fine_fascia_2"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value.fine_fascia_2"
                  label="Fine fascia 2"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :rules="value.inizio_fascia_2 ? obbligatorio_roule : []"
                  clearable
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu_fine_fascia_2"
                v-model="value.fine_fascia_2"
                full-width
                @click:minute="$refs.menu_fine_fascia_2.save(value.fine_fascia_2)"
                format="24hr"
                :min="value.inizio_fascia_2 ? value.inizio_fascia_2 : $store.getters.getFirstTime"
                :max="$store.getters.getLastTime"
                :allowed-minutes="$store.state.steps_time_picker"
                scrollable
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-combobox 
              label="Vettori"
              multiple
              v-model="value.vettori"
              :items="$store.getters.getVettoriAttivi"
              item-value="id_cliente"
              item-text="ragione_sociale"
              :rules="obbligatorio_roule"
              chips
            > 
            <!-- :readonly="[1,3].includes($store.state.permessi)" -->
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :input-value="selected"
                >
                  {{ item.ragione_sociale }}
                  <v-icon
                    small
                    @click="parent.selectItem(item)"
                  >
                    mdi-close
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="secondary"
        text
        @click="closeDialog"
      >
        Annulla
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="saveCliente"
        :disabled="loading || !valid"
        :loading="loading"
      >
        Salva
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    loading: false,
    menu_inizio_fascia_1: false,
    menu_fine_fascia_1: false,
    menu_inizio_fascia_2: false,
    menu_fine_fascia_2: false,
    obbligatorio_roule: [v => !!v || 'Campo obbligatorio!'],
    zero_obbligatorio_roule: [v => !!v || v == 0 || 'Campo obbligatorio'],
    solo_numeri_roule: [
      v => !v || !isNaN(v) || 'Inserire un numero valido!',
      v => !v || !v.includes('.') || 'Il campo consente solo numeri!',
      v => !v || !v.includes(',') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('-') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('+') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('e') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('E') || 'Il campo consente solo numeri!',
      v => !v || !v.includes('/') || 'Il campo consente solo numeri!'
    ],
    edit_rules_email: [v => !v || /.+@.+\..+/.test(v) || 'E-mail non valida!'],
  }),
  props: ['value'],
  methods: {
    closeDialog() {
      this.$emit("close-dialog")
      this.$refs.form.reset()
    },
    saveCliente() {
      this.$refs.form.validate()
      this.$nextTick(() => {
        if(!this.valid) {
          return
        }

        if (this.value.nuovo_cliente) {
          this.creaCliente()
        }
        else {
          this.modificaCliente()
        }
      })
    },
    creaCliente() {
      this.loading = true

      this.axios
        .post(`${this.$store.state.endpoint}/clienti`, {
          id_comparto: this.value.id_comparto,
          ragione_sociale: this.value.ragione_sociale,
          inizio_fascia_1: this.value.inizio_fascia_1,
          fine_fascia_1: this.value.fine_fascia_1,
          inizio_fascia_2: this.value.inizio_fascia_2,
          fine_fascia_2: this.value.fine_fascia_2,
          max_scarichi: this.value.max_scarichi,
          cd_AS400: this.value.cd_AS400,
          vettori: this.value.vettori.map(el => el["id_cliente"])
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Cliente creato con successo!"
          this.$store.state.snackbar_color = "success"

          this.$emit("update-clienti")
          this.closeDialog()
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile creare il cliente"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore cliente", e)
        })
        .finally(() => {
          this.loading = false
        });
    },
    modificaCliente() {
      this.loading = true

      this.axios
        .put(`${this.$store.state.endpoint}/clienti`, {
          id_cliente: this.value.id_cliente,
          id_comparto: this.value.id_comparto,
          ragione_sociale: this.value.ragione_sociale,
          inizio_fascia_1: this.value.inizio_fascia_1,
          fine_fascia_1: this.value.fine_fascia_1,
          inizio_fascia_2: this.value.inizio_fascia_2,
          fine_fascia_2: this.value.fine_fascia_2,
          max_scarichi: this.value.max_scarichi,
          vettori: this.value.vettori.map(el => el["id_cliente"])
        })
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = "Cliente modificato con successo!"
          this.$store.state.snackbar_color = "success"

          this.$emit("update-clienti")
          this.closeDialog()
        })
        .catch((e) => {
          this.$store.state.snackbar_text = "Non è stato possibile modificare il cliente"

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }

          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore cliente", e)
        })
        .finally(() => {
          this.loading = false
        });
    }
  }
}
</script>

<style>

</style>