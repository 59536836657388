<template>
    <div>
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <v-text-field
            v-if="value.rag_soc_cliente"
            label="Cliente"
            v-model="value.rag_soc_cliente"
            readonly
            disabled
          />
          <v-autocomplete 
            v-else
            label="Cliente"
            v-model="value.id_cliente"
            :items="$store.state.clienti"
            item-value="id_cliente"
            item-text="ragione_sociale"
            append-icon=""
            readonly
            disabled
          />
          <v-text-field 
            label="Numero bancali"
            v-model="value.bancali"
            type="number"
            readonly
            disabled
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <v-text-field
            v-if="value.tipo_scarico"
            label="Tipo scarico"
            v-model="value.tipo_scarico"
            readonly
            disabled
          />
          <v-autocomplete
            v-else
            label="Tipo scarico"
            v-model="value.id_tipo_scarico"
            :items="$store.state.tipi_scarico"
            item-value="id_tipo_scarico"
            item-text="descrizione"
            append-icon=""
            readonly
            disabled
          />
          <v-text-field 
            label="Documento di riferimetno"
            v-model="value.riferimento_documento"
            readonly
            disabled
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <v-text-field 
            label="Data"
            v-model="formattedData"
            readonly
            disabled
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <v-text-field 
            v-model="formattedFascia"
            label="Fascia Oraria"
            readonly
            disabled
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0" v-if="$store.state.permessi >= 8">
          <v-select
            label="Comparto"
            v-model="value.id_comparto"
            :items="$store.getters.getCompartiAttivi"
            item-value="id_comparto"
            item-text="codice"
          />
        </v-col>
        <v-col cols="12" class="py-0" :sm="$store.state.permessi >= 8 ? 6 : 12">
          <v-text-field 
            v-model="value.mittente"
            label="Mittente"
            readonly
            disabled
          />
        </v-col>
        <v-col v-if="$store.state.permessi >= 8 " cols="12" class="py-0">
          <v-select
            label="Stato"
            v-model="value.stato"
            :items="$store.getters.getStatiPrenotazioneAttivi"
            item-value="id_stato"
            item-text="descrizione"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <p class="pt-4 mb-2 fake-card-title" style="">Informazioni sul vettore</p>
          <v-combobox 
            label="Ragione sociale"
            v-model="value.rag_soc_vettore"
            :items="[value.rag_soc_vettore]"
            item-value="id_cliente"
            item-text="ragione_sociale"
            :maxlength="60"
            counter="60"
            append-icon=""
            readonly
            disabled
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <v-combobox 
            label="Persona di riferimento"
            v-model="value.nome_riferimento_vettore"
            :items="[value.nome_riferimento_vettore]"
            item-text="nome"
            :maxlength="60"
            counter="60"
            append-icon=""
            readonly
            disabled
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <v-text-field 
            label="Telefono"
            v-model="value.telefono_riferimento_vettore"
            :maxlength="15"
            counter="15"
            readonly
            disabled
          />
        </v-col>
        <v-col class="py-0" cols="12">
          <v-text-field 
            label="Email"
            v-model="value.email_riferimento_vettore"
            :maxlength="60"
            counter="60"
            readonly
            disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea 
            label="Note generali"
            v-model="value.note"
            readonly
            rows="3"
            disabled
          />
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  export default {
    data: () => ({
    }),
    props: ['value'],
    methods: {},
    computed: {
      formattedFascia() {
        var inizio, fine
  
        if (this.value.fascia) {
          inizio = this.value.fascia[0]
          fine = this.value.fascia[1]
        }
        else {
          inizio = this.value.ora_inizio
          fine = this.value.ora_fine
        }
  
        return `${inizio} - ${fine}`
      },
      formattedData() {
        var data = this.value.date_picker ? this.value.date_picker : this.value.data
        return new Date(data).toLocaleDateString()
      }
    }
  };
  </script>
  
  <style scoped>
  .fake-card-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
  }
  </style>
  